import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'answer';

const initialState  = {
  getAnswers: reducerUtils.initial(),
  getOneMinuteList: reducerUtils.initial(),
  getAnswerImageDetail: reducerUtils.initial(),
  getAnswerQuizDetail: reducerUtils.initial(),
  judgmentChallengeAnswerImage: reducerUtils.initial(),
  updateRejectToSuccess: reducerUtils.initial(),
  getOneAnswerImageList: reducerUtils.initial(),
  updateAnswerImageList: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getAnswers' , 'getAnswers' ) ),
    ...( createSagaReducer('getOneMinuteList' , 'getOneMinuteList' ) ),
    ...( createSagaReducer('getAnswerImageDetail' , 'getAnswerImageDetail' ) ),
    ...( createSagaReducer('getAnswerQuizDetail' , 'getAnswerQuizDetail' ) ),
    ...( createSagaReducer('judgmentChallengeAnswerImage' , 'judgmentChallengeAnswerImage' ) ),    
    ...( createSagaReducer('updateRejectToSuccess' , 'updateRejectToSuccess' ) ),
    ...( createSagaReducer('getOneAnswerImageList' , 'getOneAnswerImageList' ) ),
    ...( createSagaReducer('updateAnswerImageList' , 'updateAnswerImageList' ) ),
    reset(state) {
      state.judgmentChallengeAnswerImage = reducerUtils.initial(),
      state.updateRejectToSuccess = reducerUtils.initial(),
      state.updateAnswerImageList = reducerUtils.initial()
    }
	},
});

export const ANSWER = slice.name;
export const answerReducer = slice.reducer;
export const answerAction = slice.actions;