import { red } from '@mui/material/colors';
import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'attachment';

const initialState  = {
    response: reducerUtils.initial(),
    uploadFile: reducerUtils.initial(),
    getCommFileGrps: reducerUtils.initial(),
    updateCommFileGrpDelYn: reducerUtils.initial(),
};

const slice = createSlice({
    name ,
    initialState ,
    reducers : {
        ...( createSagaReducer('uploadFile' , 'uploadFile' ) ),
        ...( createSagaReducer('getCommFileGrps', 'getCommFileGrps')),
        ...( createSagaReducer('updateCommFileGrpDelYn', 'updateCommFileGrpDelYn')),
    
    reset: (state) => {
        state.uploadFile = reducerUtils.initial()
        state.getCommFileGrps = reducerUtils.initial()
        state.updateCommFileGrpDelYn = reducerUtils.initial()
    },
},
});

export const ATTACHMENT = slice.name;
export const attachmentReducer = slice.reducer;
export const attachmentAction = slice.actions;