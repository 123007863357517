import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'event';

const initialState  = {
  response: reducerUtils.initial(),
  getEvent: reducerUtils.initial(),
  getEvents: reducerUtils.initial(),
  insertEvent: reducerUtils.initial(),
  updateEvent: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getEvent' , 'getEvent' ) ),
    ...( createSagaReducer('getEvents' , 'getEvents' ) ),
    ...( createSagaReducer('insertEvent' , 'insertEvent' ) ),
    ...( createSagaReducer('updateEvent' , 'updateEvent' ) ),
    reset(state) {
      state.getEvent = reducerUtils.initial(),
      state.getEvents = reducerUtils.initial(),
      state.insertEvent = reducerUtils.initial(),
      state.updateEvent = reducerUtils.initial()
    }
	},
});

export const EVENT = slice.name;
export const eventReducer = slice.reducer;
export const eventAction = slice.actions;