import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { attachmentAction } from '@store/attachment/attachmentSlice'
import * as attachmentApi from '@store/attachment/attachmentApi'
import createAsyncSaga from "@store/saga.util";


const uploadFileSaga = createAsyncSaga(
  attachmentApi.uploadFile,
  attachmentAction.uploadFileSuccess,
  attachmentAction.uploadFileError,
)

const getCommFileGrpsSaga = createAsyncSaga(
  attachmentApi.getCommFileGrps,
  attachmentAction.getCommFileGrpsSuccess,
  attachmentAction.getCommFileGrpsError,
)

const updateCommFileGrpDelYnSaga = createAsyncSaga(
  attachmentApi.updateCommFileGrpDelYn,
  attachmentAction.updateCommFileGrpDelYnSuccess,
  attachmentAction.updateCommFileGrpDelYnError,
)
  
function* watchUploadFile() {
  yield takeLatest( attachmentAction.uploadFile , uploadFileSaga );
}

function* watchGetCommFileGrps() {
  yield takeLatest( attachmentAction.getCommFileGrps , getCommFileGrpsSaga );
}

function* watchUpdateCommFileGrpDelYn() {
  yield takeLatest( attachmentAction.updateCommFileGrpDelYn , updateCommFileGrpDelYnSaga );
}

export function* attachmentSaga() {
  yield all([
    fork(watchUploadFile),
    fork(watchGetCommFileGrps),
    fork(watchUpdateCommFileGrpDelYn),
  ])
}