import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '../reducer.util';
import { createSagaReducer } from '../saga.util';

const name = 'dashboard';

const initialState  = {
  getDashboardCount: reducerUtils.initial(),
  getDashboardChartProgress: reducerUtils.initial(),
  getDashboardChartChallenge: reducerUtils.initial(),
  getDashboardChartEnter: reducerUtils.initial(),
  getDashboardChartReward: reducerUtils.initial(),
  getDashboardChallenge: reducerUtils.initial(),
  getDashboardPet: reducerUtils.initial(),
  getDashboardEnteredStatus: reducerUtils.initial(),
  getDashboardJudged: reducerUtils.initial(),
  getDashboardPackage: reducerUtils.initial(),
  getDashboardTotalPet: reducerUtils.initial(),
  getDashboardChart: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getDashboardCount' , 'getDashboardCount' ) ),
    ...( createSagaReducer('getDashboardChartProgress' , 'getDashboardChartProgress' ) ),
    ...( createSagaReducer('getDashboardChartChallenge' , 'getDashboardChartProgress' ) ),
    ...( createSagaReducer('getDashboardChartEnter' , 'getDashboardChartProgress' ) ),
    ...( createSagaReducer('getDashboardChartReward' , 'getDashboardChartProgress' ) ),
    ...( createSagaReducer('getDashboardChallenge' , 'getDashboardChallenge' ) ),
    ...( createSagaReducer('getDashboardPet' , 'getDashboardPet' ) ),
    ...( createSagaReducer('getDashboardJudged' , 'getDashboardJudged' ) ),
    ...( createSagaReducer('getDashboardEnteredStatus' , 'getDashboardEnteredStatus' ) ),
    ...( createSagaReducer('getDashboardPackage' , 'getDashboardPackage' ) ),
    ...( createSagaReducer('getDashboardTotalPet' , 'getDashboardTotalPet' ) ),
    ...( createSagaReducer('getDashboardChart' , 'getDashboardChart' ) ),
	},
});

export const DASHBOARD = slice.name;
export const dashboardReducer = slice.reducer;
export const dashboardAction = slice.actions;