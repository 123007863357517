import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { answerAction } from '@store/answer/answerSlice';
import * as answerApi from '@store/answer/answerApi';
import createAsyncSaga from "@store/saga.util";

const getAnswersSaga = createAsyncSaga(
  answerApi.getAnswers,
  answerAction.getAnswersSuccess,
  answerAction.getAnswersError,
)

const getOneMinuteListSaga = createAsyncSaga(
  answerApi.getOneMinuteList,
  answerAction.getOneMinuteListSuccess,
  answerAction.getOneMinuteListError,
)

const getAnswerImageDetailSaga = createAsyncSaga(
  answerApi.getAnswerImageDetail,
  answerAction.getAnswerImageDetailSuccess,
  answerAction.getAnswerImageDetailError,
)

const getAnswerQuizDetailSaga = createAsyncSaga(
  answerApi.getAnswerQuizDetail,
  answerAction.getAnswerQuizDetailSuccess,
  answerAction.getAnswerQuizDetailError,
)

const judgmentChallengeAnswerImageSaga = createAsyncSaga(
  answerApi.judgmentChallengeAnswerImage,
  answerAction.judgmentChallengeAnswerImageSuccess,
  answerAction.judgmentChallengeAnswerImageError,
)

const updateRejectToSuccessSaga = createAsyncSaga(
  answerApi.updateRejectToSuccess,
  answerAction.updateRejectToSuccessSuccess,
  answerAction.updateRejectToSuccessError,
)

const getOneAnswerImageListSaga = createAsyncSaga(
  answerApi.getOneAnswerImageList,
  answerAction.getOneAnswerImageListSuccess,
  answerAction.getOneAnswerImageListError,
)

const updateAnswerImageListSaga = createAsyncSaga(
  answerApi.updateAnswerImageList,
  answerAction.updateAnswerImageListSuccess,
  answerAction.updateAnswerImageListError,
)

function* watchGetAnswers() {
  yield takeLatest( answerAction.getAnswers , getAnswersSaga );
}
function* watchGetOneMinuteList() {
  yield takeLatest( answerAction.getOneMinuteList , getOneMinuteListSaga );
}
function* watchGetAnswerImageDetail() {
  yield takeLatest( answerAction.getAnswerImageDetail , getAnswerImageDetailSaga );
}
function* watchGetAnswerQuizDetail() {
  yield takeLatest( answerAction.getAnswerQuizDetail , getAnswerQuizDetailSaga );
}
function* watchjudgmentChallengeAnswerImage() {
  yield takeLatest( answerAction.judgmentChallengeAnswerImage , judgmentChallengeAnswerImageSaga );
}
function* watchUpdateRejectToSuccess() {
  yield takeLatest( answerAction.updateRejectToSuccess , updateRejectToSuccessSaga );
}
function* watchGetOneAnswerImageList() {
  yield takeLatest( answerAction.getOneAnswerImageList , getOneAnswerImageListSaga );
}
function* watchGetUpdateAnswerImageList() {
  yield takeLatest( answerAction.updateAnswerImageList , updateAnswerImageListSaga );
}

export function* answerSaga() {
  yield all([
    fork(watchGetAnswers),
    fork(watchGetOneMinuteList),
    fork(watchGetAnswerImageDetail),
    fork(watchGetAnswerQuizDetail),
    fork(watchjudgmentChallengeAnswerImage),
    fork(watchUpdateRejectToSuccess),
    fork(watchGetOneAnswerImageList),
    fork(watchGetUpdateAnswerImageList),
  ])
}