import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'packageInChallenge';

const initialState  = {
  response: reducerUtils.initial(),
  getPackageInChallenges: reducerUtils.initial(),
  deletePackageInChallenge: reducerUtils.initial(),
  updatePackageInChallenge: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getPackageInChallenges' , 'getPackageInChallenges' ) ),
    ...( createSagaReducer('updatePackageInChallenge' , 'updatePackageInChallenge' ) ),
    ...( createSagaReducer('deletePackageInChallenge' , 'deletePackageInChallenge' ) ),
	},
});

export const PACKAGEINCHALLENGE = slice.name;
export const packageInChallengeReducer = slice.reducer;
export const packageInChallengeAction = slice.actions;