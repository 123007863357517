import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

// 케어예약 클리닉 기준정보 리스트 조회
export const getCsClinicList = async ({userToken, dataset}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic`,
    {
      params: dataset,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res =>  res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

// 케어예약 클리닉 기준정보 조회
export const getCsClinic = async ({userToken, csClinicId}) => {

    return axios.get(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/${csClinicId}`,
      {
        headers: {
          "Accept" : "application/json",
          "Authorization-Play" : "Bearer "+userToken
       },
      }).then(
        res =>  res.data
      ).catch((Error)=>{
        console.log(Error);
      })
  }

  // 케어예약 클리닉 기준정보 및 회의실 정보 수정
  export const updateCsClinic = async ({userToken, saveDataset}) => {
    return axios.put(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic`,
      saveDataset,
      { headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },}
    ).then(
      res => res.data
    ).catch((Error) => {
      return Error
    })
  }

  export const cacelAllClinic = async({userToken, csClinicId, data}) => {
    return axios.delete(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/${csClinicId}`,
      {
        params: data,
        headers: {
          "Accept" : "application/json",
          "Authorization-Play" : "Bearer "+userToken
       },
      }).then(
        res =>  res.data
      ).catch((Error)=>{
        console.log(Error);
      })
  }
  export const cancelRespectivelyCsClinic = async({userToken, vCsClinicId, vCsSeq, data}) => {
    return axios.delete(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/${vCsClinicId}/${vCsSeq}`,
      {
        params: data,
        headers: {
          "Accept" : "application/json",
          "Authorization-Play" : "Bearer "+userToken
       },
      }).then(
        res =>  res.data
      ).catch((Error)=>{
        console.log(Error);
      })
  }

  export const sendSchedule = async ({userToken, data}) => {

    return axios.post(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/transmission`,
      data,
      {
        headers: {
          "Accept" : "application/json",
          "Authorization-Play" : "Bearer "+userToken
       },
      }).then(
        res =>  res.data
      ).catch((Error)=>{
        console.log(Error);
      })
  }

  export const sendCsForm = async ({userToken, clinicId}) => {
    return axios.get(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/question/sms/${clinicId}`,
      {
        headers: {
          "Accept" : "application/json",
          "Authorization-Play" : "Bearer "+userToken
       },
      }).then(
        res =>  res.data
      ).catch((Error)=>{
        console.log(Error);
      })
  }
