import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'keyword';

const initialState  = {
  response: reducerUtils.initial(),
  insertKeyword: reducerUtils.initial(),
  getKeyword: reducerUtils.initial(),
  getKeywords: reducerUtils.initial(),
  updateKeyword: reducerUtils.initial(),
  getKeywordChallenge: reducerUtils.initial(),
  getKeywordPackageChallenge: reducerUtils.initial(),
  deleteKeywordChallenge: reducerUtils.initial(),
  deleteKeywordPackageChallenge: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getKeyword' , 'getKeyword' ) ),
    ...( createSagaReducer('getKeywords' , 'getKeywords' ) ),
    ...( createSagaReducer('insertKeyword' , 'insertKeyword' ) ),
    ...( createSagaReducer('updateKeyword' , 'updateKeyword' ) ),
    ...( createSagaReducer('getKeywordChallenge' , 'getKeywordChallenge' ) ),
    ...( createSagaReducer('getKeywordPackageChallenge' , 'getKeywordPackageChallenge' ) ),
    ...( createSagaReducer('deleteKeywordChallenge' , 'deleteKeywordChallenge' ) ),
    ...( createSagaReducer('deleteKeywordPackageChallenge' , 'deleteKeywordPackageChallenge' ) ),
	},
});

export const KEYWORD = slice.name;
export const keywordReducer = slice.reducer;
export const keywordAction = slice.actions;