import * as Sentry from "@sentry/nextjs";
import React from "react";
import Head from 'next/head';
import { Box, Button, Container, Typography } from '@mui/material';
import Router from "next/router";

class ErrorBoundary extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };  
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureEvent({
      message: error.message,
    });
  }

  render() {
    if (this.state.hasError) {
      // return (this.props.fallBackUIComponent);     
      return (
      // <Error/>
      <>
          <Head>
            <title>
              BadRequest
            </title>
          </Head>
          <Box
            component="main"
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              minHeight: '100%'
            }}
          >
            <Container maxWidth="md">
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="h5"
                >
                오류가 발생되었습니다. <br/>지속적으로 문제발생시 관리자에게 문의바랍니다.
                </Typography>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="subtitle2"
                >
                </Typography>
                <Box sx={{ textAlign: 'center' }}>
                  <img
                    alt="Under development"
                    src="/static/images/1200px-OOjs_UI_icon_alert-progressive.svg.png"
                    style={{
                      marginTop: 50,
                      display: 'inline-block',
                      maxWidth: '100%',
                      height: 300,
                      width: 300
                    }}
                  />
                </Box>
                <div style={{display: 'flex'}}>
                <Button
                  component="a"
                  variant="contained"
                  sx={{ ml: 3 }} 
                  onClick={() => Router.back()}
                >
                  뒤로가기
                </Button>
                <Button
                  component="a"
                  variant="contained"
                  sx={{ ml: 3 }}
                  onClick={() => {window.location.href="/"}}
                >
                  홈으로 가기
                </Button>
                </div>
              </Box>
            </Container>
          </Box>
        </>

      )
    }
    return this.props.children; 
  }
}

export default ErrorBoundary