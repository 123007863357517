import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

// Section 정보 조회(cs_section_mst)
export const getSectionMst = async ({userToken}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/sections`,
    { headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const saveSectionMst = async({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/sections`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}