import { takeLatest, put, all, fork, select, delay } from "redux-saga/effects";
import { userPetAction } from "./userPetSlice";
import * as userPetApi from "./userPetApi";
import createAsyncSaga from "../saga.util";

const getUserPetsSaga = createAsyncSaga(
  userPetApi.getUserPets,
  userPetAction.getUserPetsSuccess,
  userPetAction.getUserPetsError
);

const getUserPetsAllSaga = createAsyncSaga(
  userPetApi.getUserPetsAll,
  userPetAction.getUserPetsAllSuccess,
  userPetAction.getUserPetsAllError
);

const getUserPetsCountSaga = createAsyncSaga(
  userPetApi.getUserPetsCount,
  userPetAction.getUserPetsCountSuccess,
  userPetAction.getUserPetsCountError
);

const getUserPetDetailSaga = createAsyncSaga(
  userPetApi.getUserPetDetail,
  userPetAction.getUserPetDetailSuccess,
  userPetAction.getUserPetDetailError
);

const getUserPetClayfulIdSaga = createAsyncSaga(
  userPetApi.getUserPetClayfulId,
  userPetAction.getUserPetClayfulIdSuccess,
  userPetAction.getUserPetClayfulIdError
);

function* watchGetUserPets() {
  yield takeLatest(userPetAction.getUserPets, getUserPetsSaga);
}

function* watchGetUserPetsAll() {
  yield takeLatest(userPetAction.getUserPetsAll, getUserPetsAllSaga);
}

function* watchGetUserPetsCount() {
  yield takeLatest(userPetAction.getUserPetsCount, getUserPetsCountSaga);
}

function* watchGetUserPetDetail() {
  yield takeLatest(userPetAction.getUserPetDetail, getUserPetDetailSaga);
}

function* watchGetUserPetClayfulId() {
  yield takeLatest(userPetAction.getUserPetClayfulId, getUserPetClayfulIdSaga);
}

export function* userPetSaga() {
  yield all([
    fork(watchGetUserPets),
    fork(watchGetUserPetsAll),
    fork(watchGetUserPetsCount),
    fork(watchGetUserPetDetail),
    fork(watchGetUserPetClayfulId),
  ]);
}
