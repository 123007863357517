import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'csFormSection';

const initialState  = {
  response: reducerUtils.initial(),
  getSectionMst: reducerUtils.initial(),
  saveSectionMst: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getSectionMst' , 'getSectionMst' ) ), 
    ...( createSagaReducer('saveSectionMst' , 'saveSectionMst' ) ), 
    reset(state) {
      state.getSectionMst = reducerUtils.initial(),
      state.saveSectionMst = reducerUtils.initial()
    }
	},
});

export const CSFORMSECTION = slice.name;
export const csFormSectionReducer = slice.reducer;
export const csFormSectionAction = slice.actions;