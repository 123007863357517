import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { csFormSectionAction } from '@store/csb/section/csFormSectionSlice';
import * as csFormSectionApi from '@store/csb/section/csFormSectionApi';
import createAsyncSaga from "@store/saga.util";
import { create } from "lodash";

// Section 정보 조회(cs_section_mst)
const getSectionMstSaga = createAsyncSaga(
  csFormSectionApi.getSectionMst,
  csFormSectionAction.getSectionMstSuccess,
  csFormSectionAction.getSectionMstError,
)

const postSectionMstSaga = createAsyncSaga(
  csFormSectionApi.saveSectionMst,
  csFormSectionAction.saveSectionMstSuccess,
  csFormSectionAction.saveSectionMstError,
)

function* watchGetSectionMst() {
  yield takeLatest( csFormSectionAction.getSectionMst , getSectionMstSaga );
}

function* watchPostSectionMst() {
  yield takeLatest( csFormSectionAction.saveSectionMst , postSectionMstSaga );
}

export function* csFormSectionSaga() {
  yield all([,
    fork(watchGetSectionMst),
    fork(watchPostSectionMst),
  ])
}