import axios from 'axios'
import { HeaderBasic } from '../api.util'

export const SERVER_API_HOST = process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST

export const getCategorys = async ({userToken, data}) => {

  return axios.get(
    `${SERVER_API_HOST}/admin/category/select`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}


export const getCategoryDetail = async (id) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/category/select/${id}`,
    {
      headers: {
        ...HeaderBasic,
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const insertCategory = async (data) => {
  return axios.post(
    `${SERVER_API_HOST}/admin/category/insert`,
    data,
    {
      headers: {
        ...HeaderBasic,
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      return Error
    })
}

export const updateCategory = async (data) => {
  return axios.put(
    `${SERVER_API_HOST}/admin/category/update`,
    data,
    {
      headers: {
        ...HeaderBasic,
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      return Error
    })
}