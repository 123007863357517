import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'commonCode';

const initialState  = {
  response: reducerUtils.initial(),
  getCommonCodeList: reducerUtils.initial(),
  getCommonMainCodeList: reducerUtils.initial(),
  insertCommonCode: reducerUtils.initial(),
  deleteCommonCode: reducerUtils.initial(),
  updateCommonCode: reducerUtils.initial(),
  updateCommonCodeList: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getCommonCodeList' , 'getCommonCodeList' ) ),
    ...( createSagaReducer('getCommonMainCodeList' , 'getCommonMainCodeList' ) ),
    ...( createSagaReducer('insertCommonCode' , 'insertCommonCode' ) ),
    ...( createSagaReducer('deleteCommonCode' , 'deleteCommonCode' ) ),
    ...( createSagaReducer('updateCommonCode' , 'updateCommonCode' ) ),
    ...( createSagaReducer('updateCommonCodeList' , 'updateCommonCodeList' ) ),
    reset: (state, action) => {
      state.getCommonCodeList = reducerUtils.initial()
      state.getCommonMainCodeList = reducerUtils.initial()
      state.insertCommonCode = reducerUtils.initial()
      state.deleteCommonCode = reducerUtils.initial()
      state.updateCommonCode = reducerUtils.initial()
      state.updateCommonCodeList = reducerUtils.initial()
    },
	},
});

export const COMMONCODE = slice.name;
export const commonCodeReducer = slice.reducer;
export const commonCodeAction = slice.actions;