import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { csFormRespAction } from '@store/csm/formResp/csFormRespSlice';
import * as csFormRespApi from '@store/csm/formResp/csFormRespApi';
import createAsyncSaga from "@store/saga.util";

const getCsFormRespSaga = createAsyncSaga(
  csFormRespApi.getCsFormResp,
  csFormRespAction.getCsFormRespSuccess,
  csFormRespAction.getCsFormRespError,
)

const getCsFormRespDetailsSaga = createAsyncSaga(
  csFormRespApi.getCsFormRespDetails,
  csFormRespAction.getCsFormRespDetailsSuccess,
  csFormRespAction.getCsFormRespDetailsError,
)

const responseConfirmSaga = createAsyncSaga(
  csFormRespApi.responseConfirm,
  csFormRespAction.responseConfirmSuccess,
  csFormRespAction.responseConfirmError,
)

const getCsCustInfoSaga = createAsyncSaga(
  csFormRespApi.getCsCustInfo,
  csFormRespAction.getCsCustInfoSuccess,
  csFormRespAction.getCsCustInfoError,
)

const getCsPetInfoSaga = createAsyncSaga(
  csFormRespApi.getCsPetInfo,
  csFormRespAction.getCsPetInfoSuccess,
  csFormRespAction.getCsPetInfoError,
)

function* watchGetCsFormResp() {
  yield takeLatest( csFormRespAction.getCsFormResp , getCsFormRespSaga );
}

function* watchGetCsFormRespDetails() {
  yield takeLatest ( csFormRespAction.getCsFormRespDetails, getCsFormRespDetailsSaga);
}

function* watchResponseConfirm() {
  yield takeLatest ( csFormRespAction.responseConfirm, responseConfirmSaga) ;
}

function* watchGetCsCustInfo() {
  yield takeLatest( csFormRespAction.getCsPetInfo , getCsCustInfoSaga );
}

function* watchGetCsPetInfo() {
  yield takeLatest( csFormRespAction.getCsPetInfo , getCsPetInfoSaga );
}

export function* csFormRespSaga() {
  yield all([
    fork(watchGetCsFormResp),
    fork(watchGetCsFormRespDetails),
    fork(watchResponseConfirm),
    fork(watchGetCsCustInfo),
    fork(watchGetCsPetInfo),
  ])
}