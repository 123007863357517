import axios from 'axios'

export const postUserPointPlusOrMinus = async ({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/point`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
    }}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}