import axios from 'axios'
import { HeaderBasic } from '../api.util'

export const getUserPlayEnteredHistory = async ({userToken, clayfulId}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/user/play/${clayfulId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}