import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'packageChallenge';

const initialState  = {
  response: reducerUtils.initial(),
  getPackageChallenge: reducerUtils.initial(),
  getPackageChallenges: reducerUtils.initial(),
  insertPackageChallenge: reducerUtils.initial(),
  updatePackageChallenge: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getPackageChallenge' , 'getPackageChallenge' ) ),
    ...( createSagaReducer('getPackageChallenges' , 'getPackageChallenges' ) ),
    ...( createSagaReducer('insertPackageChallenge' , 'insertPackageChallenge' ) ),
    ...( createSagaReducer('updatePackageChallenge' , 'updatePackageChallenge' ) ),
    reset(state) {
      state.getPackageChallenge = reducerUtils.initial(),
      state.getPackageChallenges = reducerUtils.initial(),
      state.insertPackageChallenge = reducerUtils.initial(),
      state.updatePackageChallenge = reducerUtils.initial()
    }
	},
});

export const PACKAGECHALLENGE = slice.name;
export const packageChallengeReducer = slice.reducer;
export const packageChallengeAction = slice.actions;