import axios from "axios";
import { HeaderBasic } from "../api.util";

export const SERVER_API_HOST = process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST;

export const getUserPets = async ({ userToken, data }) => {
  return axios
    .get(`${SERVER_API_HOST}/user/pet/select`, {
      params: data,
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getUserPetsAll = async ({ userToken, data }) => {
  return axios
    .get(`${SERVER_API_HOST}/user/pet/select`, {
      params: data,
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getUserPetsCount = async ({ userToken, data }) => {
  return axios
    .get(`${SERVER_API_HOST}/user/pet/count`, {
      params: data,
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getUserPetDetail = async ({ userToken, userPetId }) => {
  return axios
    .get(`${SERVER_API_HOST}/user/pet/select/${userPetId}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getUserPetClayfulId = async ({ userToken, clayfulId }) => {
  return axios
    .get(`${SERVER_API_HOST}/user/pet/${clayfulId}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};
