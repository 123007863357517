import { takeLatest, put, all, fork, select, delay, call } from "redux-saga/effects"
import * as orderApi from './orderApi';
import createAsyncSaga from "../saga.util";
import { orderAction } from "./orderSlice";
import { chunkRequest } from "@utils/helper";
import { ORDER_ITEM_KNOLLO_VENDOR, ORDER_STATUS } from "@constants/order";
import { snackbarAction } from "@store/snackbar/snackbarSlice";
import dayjs from "dayjs";


const getOrderListSaga = createAsyncSaga(
    orderApi.getOrderList,
    orderAction.getOrderListSuccess,
    orderAction.getOrderListError,
)

const getOrderCountSaga = createAsyncSaga(
    orderApi.getOrderCount,
    orderAction.getOrderCountSuccess,
    orderAction.getOrderCountError,
)


// 테스트용 삭제 코드
function* deleteFulfillmentsSaga(action){
    try{
        let filterdPayload = action.payload;
        delete filterdPayload['createdAt>'];
        delete filterdPayload['received'];
        delete filterdPayload['vendor'];
        delete filterdPayload['fulfilled'];
        const res = yield call(orderApi.getOrderList, {...filterdPayload, fulfillmentStatus: "pending"});
        console.log(res);

        for(let i=0; i<res.length; i++){
            const orderId = res[i]._id;
            const fulfillmentIds = res[i].fulfillments.map(d => d._id);
            for(let j=0; j<fulfillmentIds.length; j++){
                console.log(orderId, fulfillmentIds[j]);
                const res2 = yield call(orderApi.deleteFulfillmentItem, {id1: orderId, id2: fulfillmentIds[j]});
            }
        }

        // yield put(orderAction.deleteFulfillmentsSuccess(res));
    }catch(e){
        console.log(e);
        // yield put(orderAction.deleteFulfillmentsError(e));
    }
}

function* getAllOrderForCSVSaga(action){
    try{
        const OrderTotal = yield select((state) => state.order.getOrderCount.data.count.raw);
        const LIMIT_SIZE = 100;
        let endPage = Math.floor(OrderTotal / LIMIT_SIZE) + 1;
        let arr = [];
        for(let i=1; i<=endPage; i++){
            const res = yield call(orderApi.getOrderList, {
                ...action.payload,
                page: i,
                limit: LIMIT_SIZE
            });
            arr=[...arr, ...res];
        }
        yield put(orderAction.getAllOrderForCSVSuccess(arr));
    }catch(e){
        console.log(e);
        yield put(orderAction.getAllOrderForCSVError(e));
    }
}

function* getAllOrderListAndFulfillmentSaga(action){
    try{
        const orders = yield select((state) => state.order.getAllOrderPrepareShipping.data);
        
        const {
            getOrderList,
            getOrderCount,
        } = yield select((state) => state.order);
        let arr = [];  

        
        let filteredOrders = orders.filter(d => d.items.length > 0);
        filteredOrders = filteredOrders.map(d => {
            let tempArr = [];
            d.items.forEach(d2 => {
                if (d2.vendor?._id === ORDER_ITEM_KNOLLO_VENDOR) {
                    tempArr.push({
                        item: d2._id,
                        quantity: d2.quantity.raw,
                    })
                }
                // 추가옵션으로 구매한 경우
                if (d2.bundleItems?.length > 0) {
                    d2.bundleItems.forEach(d3 => {
                        if (d3.vendor._id === ORDER_ITEM_KNOLLO_VENDOR) {
                            tempArr.push({
                                item: d3._id,
                                quantity: d3.quantity.raw,
                            })
                        }
                    })
                }
            })
            return {
                orderId: d._id,
                items: tempArr
            }
        })
        const res = yield call(chunkRequest, filteredOrders, 10);
        
        if(
            res.successed.length > 0 &&
            res.failed.length === 0
        ){
            yield put(snackbarAction.setSnackbar({ isOpen: true, type:'success', message: '작업이 모두 완료되었습니다.' })); 
        } else if(
            res.successed.length === 0 &&
            res.failed.length > 0
        ){
            yield put(snackbarAction.setSnackbar({ isOpen: true, type:'error', message: '작업이 모두 실패했습니다.' })); 
        } else {
            yield put(snackbarAction.setSnackbar({ isOpen: true, type:'warning', message: '일부 주문이 성공/실패했습니다.' }));
        }
        yield put(orderAction.setFulfillmentResults({
            success: res.successed,
            fail: res.failed
        }))
        yield put(orderAction.getAllOrderListAndFulfillmentSuccess(res));
    }catch(e){
        console.log(e);
        yield put(snackbarAction.setSnackbar({ isOpen: true, type:'error', message: '작업이 실패했습니다.' }));
        yield put(orderAction.getAllOrderListAndFulfillmentError(e));
    }
}

function* selectedOrdersToFulfillmentSaga(action){
    try{
        let arr = [];
        let data = action.payload.items;
        data = data.filter(d => d.items.length > 0);
        data = data.map(d => {
            let tempArr = [];
            d.items.forEach(d2 => {
                if (d2.vendor?._id === ORDER_ITEM_KNOLLO_VENDOR) {
                    tempArr.push({
                        item: d2._id,
                        quantity: d2.quantity.raw,
                    })
                }
                // 추가옵션으로 구매한 경우
                if (d2.bundleItems?.length > 0) {
                    d2.bundleItems.forEach(d3 => {
                        if (d3.vendor._id === ORDER_ITEM_KNOLLO_VENDOR) {
                            tempArr.push({
                                item: d3._id,
                                quantity: d3.quantity.raw,
                            })
                        }
                    })
                }
            })
            return {
                orderId: d._id,
                items: tempArr
            }
        })
        data = data.filter(d => d.items.length > 0);
        arr.push(...data);
        const res = yield call(chunkRequest, arr, 10);
        if(
            res.successed.length > 0 &&
            res.failed.length === 0
        ){
            yield put(snackbarAction.setSnackbar({ isOpen: true, type:'success', message: '작업이 모두 완료되었습니다.' })); 
        } else if(
            res.successed.length === 0 &&
            res.failed.length > 0
        ){
            yield put(snackbarAction.setSnackbar({ isOpen: true, type:'error', message: '작업이 모두 실패했습니다.' })); 
        } else {
            yield put(snackbarAction.setSnackbar({ isOpen: true, type:'warning', message: '일부 주문이 성공/실패했습니다.' }));
        }
        
        // window.location.reload(); 
        yield put(orderAction.setFulfillmentResults({
            success: res.successed,
            fail: res.failed
        }))
        yield put(orderAction.selectedOrdersToFulfillmentSuccess(res));
    } catch (err) {
        yield put(snackbarAction.setSnackbar({ isOpen: true, type:'error', message: '작업이 실패했습니다.' }));
        yield put(orderAction.selectedOrdersToFulfillmentError(err));
    }
}


function* getAllOrderPrepareShippingSaga(action){
    try{
        const res = yield call(orderApi.getOrderCount, action.payload);
        const totalCount = res.count.raw;
        let arr = [];
        const LIMIT_SIZE = 100;
        
        let pageCount = Math.floor(totalCount / LIMIT_SIZE) + 1;
        for(let i=0; i<pageCount; i++){
            let filteredPayload = { ...action.payload };
            delete filteredPayload.totalLength;
            let data = yield call(orderApi.getOrderList, {
                ...filteredPayload,
                page: i+1,
                limit: LIMIT_SIZE
            });
            let filtered = data.filter(orderItem => {
                let allItemIds = [];
                let fulfillmentedItemIds = [];
                orderItem.items.forEach(item => {
                    if(item.vendor && item.vendor._id === ORDER_ITEM_KNOLLO_VENDOR){
                        allItemIds.push(item._id);
                    }
                })
                orderItem.fulfillments.forEach(fulfillment => {
                    fulfillment.items.forEach(fulfillmentItem => {
                        fulfillmentedItemIds.push(fulfillmentItem.item._id);
                    })
                })
                let flag = allItemIds.some(element => !fulfillmentedItemIds.includes(element));
                if(flag){
                    return true;
                }
            })

            arr.push(...filtered);
        }
        yield put(orderAction.getAllOrderPrepareShippingSuccess(arr));
        yield put(orderAction.setCurrentOrderList(arr.slice(0,100)));
    }catch(e){
        yield put(snackbarAction.setSnackbar({ isOpen: true, type:'error', message: '작업이 실패했습니다.' }));
        yield put(orderAction.getAllOrderPrepareShippingError(e));
    }
}

const getShippingPoliciesSaga = createAsyncSaga(
    orderApi.getShippingPolicies,
    orderAction.getShippingPoliciesSuccess,
    orderAction.getShippingPoliciesError,
)





function* watchOrderListSaga(){
    yield takeLatest(orderAction.getOrderList, getOrderListSaga);
}

function* watchOrderCountSaga(){
    yield takeLatest(orderAction.getOrderCount, getOrderCountSaga);
}

function* watchGetAllOrderListAndFulfillment(){
    yield takeLatest(orderAction.getAllOrderListAndFulfillment, getAllOrderListAndFulfillmentSaga);
}

function* watchSelectedOrdersToFulfillment(){
    yield takeLatest(orderAction.selectedOrdersToFulfillment, selectedOrdersToFulfillmentSaga);
}

function* watchGetAllOrderPrepareShipping(){
    yield takeLatest(orderAction.getAllOrderPrepareShipping, getAllOrderPrepareShippingSaga);
}

function* watchGetAllOrderForCSV(){
    yield takeLatest(orderAction.getAllOrderForCSV, getAllOrderForCSVSaga);
}

function* watchGetShippingPolicies(){
    yield takeLatest(orderAction.getShippingPolicies, getShippingPoliciesSaga);
}

// 테스트용 삭제 코드
function* watchDeleteFulfillments(){
    yield takeLatest(orderAction.deleteFulfillments, deleteFulfillmentsSaga);
}

export function* orderSaga(){
    yield all([
        fork(watchOrderListSaga),
        fork(watchOrderCountSaga),
        fork(watchGetAllOrderListAndFulfillment),
        fork(watchSelectedOrdersToFulfillment),
        fork(watchGetAllOrderPrepareShipping),
        fork(watchGetAllOrderForCSV),
        fork(watchDeleteFulfillments),
        fork(watchGetShippingPolicies),
    ])
}