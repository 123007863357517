import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { csClinicResultAction } from '@store/csm/clinic/result/csClinicResultSlice';
import * as csClinicResultApi from '@store/csm/clinic/result/csClinicResultApi';
import createAsyncSaga from "@store/saga.util";

const getCsClinicResultSaga = createAsyncSaga(
  csClinicResultApi.getCsClinicResult,
  csClinicResultAction.getCsClinicResultSuccess,
  csClinicResultAction.getCsClinicResultError,
)

const getCalendarSchedulesSaga = createAsyncSaga(
  csClinicResultApi.getCalendarSchedules,
  csClinicResultAction.getCalendarSchedulesSuccess,
  csClinicResultAction.getCalendarSchedulesError,
)

const getCsClinicResultByCaseSaga = createAsyncSaga(
  csClinicResultApi.getCsClinicResultByCase,
  csClinicResultAction.getCsClinicResultByCaseSuccess,
  csClinicResultAction.getCsClinicResultByCaseError,
)

const updateCsClinicResultSaga = createAsyncSaga(
  csClinicResultApi.updateCsClinicResult,
  csClinicResultAction.updateCsClinicResultSuccess,
  csClinicResultAction.updateCsClinicResultError,
)

const postResendReportSaga = createAsyncSaga(
  csClinicResultApi.postResendReport,
  csClinicResultAction.postResendReportSuccess,
  csClinicResultAction.postResendReportError,
)

function* watchGetCsClinicResult() {
  yield takeLatest( csClinicResultAction.getCsClinicResult , getCsClinicResultSaga );
}

function* watchGetCalendarSchedules() {
  yield takeLatest( csClinicResultAction.getCalendarSchedules , getCalendarSchedulesSaga );
}

function* watchGetCsClinicResultByCase() {
  yield takeLatest( csClinicResultAction.getCsClinicResultByCase, getCsClinicResultByCaseSaga);
}

function* watchUpdateCsClinicResult() {
  yield takeLatest( csClinicResultAction.updateCsClinicResult, updateCsClinicResultSaga);
}

function* watchPostResendReport() {
  yield takeLatest( csClinicResultAction.postResendReport, postResendReportSaga);
}


export function* csClinicResultSaga() {
  yield all([
    fork(watchGetCsClinicResult),
    fork(watchGetCalendarSchedules),
    fork(watchGetCsClinicResultByCase),
    fork(watchUpdateCsClinicResult),
    fork(watchPostResendReport),
  ])
}