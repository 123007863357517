import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { csClinicAction } from '@store/csm/clinic/csClinicSlice';
import * as csClinicApi from '@store/csm/clinic/csClinicApi';
import createAsyncSaga from "@store/saga.util";

const getCsClinicListSaga = createAsyncSaga(
  csClinicApi.getCsClinicList,
  csClinicAction.getCsClinicListSuccess,
  csClinicAction.getCsclinicListError,
)

const getCsClinicSaga = createAsyncSaga(
    csClinicApi.getCsClinic,
    csClinicAction.getCsClinicSuccess,
    csClinicAction.getCsclinicError,
)

const putCsClinicSaga = createAsyncSaga(
  csClinicApi.updateCsClinic,
  csClinicAction.updateCsClinicSuccess,
  csClinicAction.updateCsclinicError,
)

const cancelRespectivelyCsClinicSaga = createAsyncSaga(
  csClinicApi.cancelRespectivelyCsClinic,
  csClinicAction.cancelRespectivelyCsClinicSuccess,
  csClinicAction.cancelRespectivelyCsClinicError,
)

const sendScheduleSaga = createAsyncSaga(
  csClinicApi.sendSchedule,
  csClinicAction.sendScheduleSuccess,
  csClinicAction.sendScheduleError,
)

const sendCsFormSaga = createAsyncSaga(
  csClinicApi.sendCsForm,
  csClinicAction.sendCsFormSuccess,
  csClinicAction.sendCsFormError,
)

const cacelAllClinicSaga = createAsyncSaga(
  csClinicApi.cacelAllClinic,
  csClinicAction.cacelAllClinicSuccess,
  csClinicAction.cacelAllClinicError,
)


function* watchGetCsClinicList() {
  yield takeLatest( csClinicAction.getCsClinicList , getCsClinicListSaga );
}

function* watchGetCsClinic() {
  yield takeLatest( csClinicAction.getCsClinic , getCsClinicSaga );
}

function* watchPutCsClinic() {
  yield takeLatest( csClinicAction.updateCsClinic , putCsClinicSaga );
}

function* watchCancelRespectivelyCsClinic() {
  yield takeLatest( csClinicAction.cancelRespectivelyCsClinic , cancelRespectivelyCsClinicSaga );
}

function* watchSendSchedule() {
  yield takeLatest( csClinicAction.sendSchedule , sendScheduleSaga );
}

function* watchSendCsForm() {
  yield takeLatest( csClinicAction.sendCsForm , sendCsFormSaga );
}

function* watchCancelAllClinic() {
  yield takeLatest( csClinicAction.cacelAllClinic, cacelAllClinicSaga);
}

export function* csClinicSaga() {
  yield all([
    fork(watchGetCsClinicList),
    fork(watchGetCsClinic),
    fork(watchPutCsClinic),
    fork(watchCancelRespectivelyCsClinic),
    fork(watchSendSchedule),
    fork(watchSendCsForm),
    fork(watchCancelAllClinic),
  ])
}