import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { categoryAction } from './categorySlice'
import * as categoryApi from './categoryApi'
import createAsyncSaga from "../saga.util";

const getCategorysSaga = createAsyncSaga(
  categoryApi.getCategorys,
  categoryAction.getCategorysSuccess,
  categoryAction.getCategorysError,
)

const getCategoryDetailSaga = createAsyncSaga(
  categoryApi.getCategoryDetail,
  categoryAction.getCategoryDetailSuccess,
  categoryAction.getCategoryDetailError,
)

const insertCategorySaga = createAsyncSaga(
  categoryApi.insertCategory,
  categoryAction.insertCategorySuccess,
  categoryAction.insertCategoryError,
)

const updateCategorySaga = createAsyncSaga(
  categoryApi.updateCategory,
  categoryAction.updateCategorySuccess,
  categoryAction.updateCategoryError,
)

function* watchGetCategorys() {
  yield takeLatest( categoryAction.getCategorys , getCategorysSaga );
}

function* watchGetCategoryDetail() {
  yield takeLatest( categoryAction.getCategoryDetail , getCategoryDetailSaga );
}

function* watchInsertCategory() {
  yield takeLatest( categoryAction.insertCategory , insertCategorySaga );
}

function* watchUpdateCategory() {
  yield takeLatest( categoryAction.updateCategory , updateCategorySaga );
}

export function* categorySaga() {
  yield all([
    fork(watchGetCategorys),
    fork(watchGetCategoryDetail),
    fork(watchInsertCategory),
    fork(watchUpdateCategory),
  ])
}