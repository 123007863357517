import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { challengeAction } from '@store/challenge/challengeSlice';
import * as challengeApi from '@store/challenge/challengeApi';
import createAsyncSaga from "@store/saga.util";

const getChallengeSaga = createAsyncSaga(
  challengeApi.getChallenge,
  challengeAction.getChallengeSuccess,
  challengeAction.getChallengeError,
)

const getChallengesSaga = createAsyncSaga(
  challengeApi.getChallenges,
  challengeAction.getChallengesSuccess,
  challengeAction.getChallengesError,
)

const postChallengeSaga = createAsyncSaga(
  challengeApi.insertChallenge,
  challengeAction.insertChallengeSuccess,
  challengeAction.insertChallengeError,
)

const putChallengeSaga = createAsyncSaga(
  challengeApi.updateChallenge,
  challengeAction.updateChallengeSuccess,
  challengeAction.updateChallengeError,
)

const getOneMinuteChallengeListSaga = createAsyncSaga(
  challengeApi.getOneMinuteChallengeList,
  challengeAction.getOneMinuteChallengeListSuccess,
  challengeAction.getOneMinuteChallengeListError,
)

function* watchGetChallenge() {
  yield takeLatest( challengeAction.getChallenge , getChallengeSaga );
}

function* watchGetChallenges() {
  yield takeLatest( challengeAction.getChallenges , getChallengesSaga );
}

function* watchPostChallenge() {
  yield takeLatest( challengeAction.insertChallenge , postChallengeSaga );
}

function* watchPutChallenge() {
  yield takeLatest( challengeAction.updateChallenge , putChallengeSaga );
}

function* watchGetOneMinuteChallengeList() {
  yield takeLatest( challengeAction.getOneMinuteChallengeList , getOneMinuteChallengeListSaga );
}

export function* challengeSaga() {
  yield all([
    fork(watchGetChallenge),
    fork(watchGetChallenges),
    fork(watchPostChallenge),
    fork(watchPutChallenge),
    fork(watchGetOneMinuteChallengeList),
  ])
}