import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'knolloStore';

const initialState  = {
  response: reducerUtils.initial(),
  deletePaidPending: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('deletePaidPending' , 'deletePaidPending' ) ),
	},
});

export const KNOLLOSTORE = slice.name;
export const knolloStoreReducer = slice.reducer;
export const knolloStoreAction = slice.actions;