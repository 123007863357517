import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getKeyword = async ({userToken, keywordId}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/select/${keywordId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getKeywords = async ({userToken, data = null }) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/select`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
}

export const insertKeyword = async ({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/insert`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const updateKeyword = async ({userToken, data}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/update`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
  
}

export const getKeywordChallenge = async ({userToken, keywordId}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/challenge/select/${keywordId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getKeywordPackageChallenge = async ({userToken, keywordId}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/package/select/${keywordId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const deleteKeywordChallenge = async ({userToken, insertDataSet = null}) => {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/challenge/delete`,
    {
      params: insertDataSet,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const deleteKeywordPackageChallenge = async ({userToken, insertDataSet = null}) => {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/keyword/package/delete`,
    {
      params: insertDataSet,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}