import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "../reducer.util";
import { createSagaReducer } from "../saga.util";

const name = "userPet";

const initialState = {
  getUserPets: reducerUtils.initial(),
  getUserPetsAll: reducerUtils.initial(),
  getUserPetsCount: reducerUtils.initial(),
  getUserPetDetail: reducerUtils.initial(),
  getUserPetClayfulId: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getUserPets", "getUserPets"),
    ...createSagaReducer("getUserPetsAll", "getUserPetsAll"),
    ...createSagaReducer("getUserPetsCount", "getUserPetsCount"),
    ...createSagaReducer("getUserPetDetail", "getUserPetDetail"),
    ...createSagaReducer("getUserPetClayfulId", "getUserPetClayfulId"),
    reset: (state, action) => {
      state.getUserPetsAll = reducerUtils.initial();
      state.getUserPetsCount = reducerUtils.initial();
    },
  },
});

export const USERPET = slice.name;
export const userPetReducer = slice.reducer;
export const userPetAction = slice.actions;
