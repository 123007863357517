import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const deletePaidPending = async ({ data }) => {
  
  return axios.post(
    `${process.env.NEXT_PUBLIC_STORE_DEV_API_HOST}/cron/placed/admin/cancel`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization" : "Bearer zpTPJ57052KESanYtsYl"
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}