import { ORDER_SEARCH_PRODUCT_NAME } from "@constants/order";
import { HeaderBasic } from "@store/api.util";
import axios from "axios";

export const getOrders = async (payload) => {
    if(payload[ORDER_SEARCH_PRODUCT_NAME] === "") delete payload[ORDER_SEARCH_PRODUCT_NAME];
    const params = {
        ...payload,
        ...(payload.status && { status: payload.status.join(',') }),
        searchMatch: 'partial'
    };
    return await axios.get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders`, {
        headers: {
            ...HeaderBasic,
        },
        params
    })
        .then(res => res.data)
        .catch(Error => console.log(Error))
};

