import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { dashboardAction } from './dashboardSlice'
import * as dashboardApi from './dashboardApi'
import createAsyncSaga from "../saga.util";

const getDashboardCountSaga = createAsyncSaga(
  dashboardApi.getDashboardCount,
  dashboardAction.getDashboardCountSuccess,
  dashboardAction.getDashboardCountError,
)

const getDashboardChartProgressSaga = createAsyncSaga(
  dashboardApi.getDashboardChartProgress,
  dashboardAction.getDashboardChartProgressSuccess,
  dashboardAction.getDashboardChartProgressError,
)

const getDashboardChartChallengeSaga = createAsyncSaga(
  dashboardApi.getDashboardChartChallenge,
  dashboardAction.getDashboardChartChallengeSuccess,
  dashboardAction.getDashboardChartChallengeError,
)

const getDashboardChartEnterSaga = createAsyncSaga(
  dashboardApi.getDashboardChartEnter,
  dashboardAction.getDashboardChartEnterSuccess,
  dashboardAction.getDashboardChartEnterError,
)

const getDashboardChartRewardSaga = createAsyncSaga(
  dashboardApi.getDashboardChartReward,
  dashboardAction.getDashboardChartRewardSuccess,
  dashboardAction.getDashboardChartRewardError,
)

const getDashboardChallengeSaga = createAsyncSaga(
  dashboardApi.getDashboardChallenge,
  dashboardAction.getDashboardChallengeSuccess,
  dashboardAction.getDashboardChallengeError,
)

const getDashboardPetSaga = createAsyncSaga(
  dashboardApi.getDashboardPet,
  dashboardAction.getDashboardPetSuccess,
  dashboardAction.getDashboardPetError,
)

const getDashboardJudgedSaga = createAsyncSaga(
  dashboardApi.getDashboardJudged,
  dashboardAction.getDashboardJudgedSuccess,
  dashboardAction.getDashboardJudgedError,
)

const getDashboardEnteredStatusSaga = createAsyncSaga(
  dashboardApi.getDashboardEnteredStatus,
  dashboardAction.getDashboardEnteredStatusSuccess,
  dashboardAction.getDashboardEnteredStatusError,
)

const getDashboardPackageSaga = createAsyncSaga(
  dashboardApi.getDashboardPackage,
  dashboardAction.getDashboardPackageSuccess,
  dashboardAction.getDashboardPackageError,
)

const getDashboardTotalPetSaga = createAsyncSaga(
  dashboardApi.getDashboardTotalPet,
  dashboardAction.getDashboardTotalPetSuccess,
  dashboardAction.getDashboardTotalPetError,
)

const getDashboardChartSaga = createAsyncSaga(
  dashboardApi.getDashboardChart,
  dashboardAction.getDashboardChartSuccess,
  dashboardAction.getDashboardChartError,
)

function* watchGetDashboardCount() {
  yield takeLatest( dashboardAction.getDashboardCount , getDashboardCountSaga );
}

function* watchGetDashboardChartProgress() {
  yield takeLatest( dashboardAction.getDashboardChartProgress , getDashboardChartProgressSaga );
}

function* watchGetDashboardChartChallenge() {
  yield takeLatest( dashboardAction.getDashboardChartChallenge , getDashboardChartChallengeSaga );
}

function* watchGetDashboardChartEnter() {
  yield takeLatest( dashboardAction.getDashboardChartEnter , getDashboardChartEnterSaga );
}

function* watchGetDashboardChartReward() {
  yield takeLatest( dashboardAction.getDashboardChartReward , getDashboardChartRewardSaga );
}

function* watchGetDashboardChallenge() {
  yield takeLatest( dashboardAction.getDashboardChallenge , getDashboardChallengeSaga );
}

function* watchGetDashboardPet() {
  yield takeLatest( dashboardAction.getDashboardPet , getDashboardPetSaga );
}

function* watchGetDashboardJudgedStatus() {
  yield takeLatest( dashboardAction.getDashboardJudged , getDashboardJudgedSaga );
}

function* watchGetDashboardEnteredStatus() {
  yield takeLatest( dashboardAction.getDashboardEnteredStatus , getDashboardEnteredStatusSaga );
}

function* watchGetDashboardPackage() {
  yield takeLatest( dashboardAction.getDashboardPackage , getDashboardPackageSaga );
}

function* watcGetDashboardTotalPet() {
  yield takeLatest( dashboardAction.getDashboardTotalPet , getDashboardTotalPetSaga );
}

function* watcGetDashboarChart() {
  yield takeLatest( dashboardAction.getDashboardChart , getDashboardChartSaga );
}

export function* dashboardSaga() {
  yield all([
    fork(watchGetDashboardCount),
    fork(watchGetDashboardChartProgress),
    fork(watchGetDashboardChartChallenge),
    fork(watchGetDashboardChartEnter),
    fork(watchGetDashboardChartReward),
    fork(watchGetDashboardChallenge),
    fork(watchGetDashboardPet),
    fork(watchGetDashboardEnteredStatus),
    fork(watchGetDashboardJudgedStatus),
    fork(watchGetDashboardPackage),
    fork(watcGetDashboardTotalPet),
    fork(watcGetDashboarChart),
  ])
}