import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { accountAction } from '@store/account/accountSlice'
import * as accountApi from '@store/account/accountApi'
import createAsyncSaga from "@store/saga.util";

const getAccountsSaga = createAsyncSaga(
  accountApi.getAccounts,
  accountAction.getAccountsSuccess,
  accountAction.getAccountsError,
)


function* watchGetAccounts() {
  yield takeLatest( accountAction.getAccounts , getAccountsSaga );
}

export function* accountSaga() {
  yield all([
    fork(watchGetAccounts),
  ])
}