import axios from 'axios';
import { HeaderBasic, HeaderEncod, HeaderFormData } from "@store/api.util";
import { ORDER_ITEM_KNOLLO_VENDOR } from '@constants/order';

/**
 * @param {Object} payload - The payload for the request.
 * @param {Array<string>} [payload.status]
 * @param {string} payload.ids
 * @param {string} payload['createdAt>']
 * @param {string} payload['search:customer.email<']
 * @param {string} payload['search:customer.mobile']
 * @param {string} payload['search:customer.name.full']
 * @returns {Promise<Object>} The response data containing the order count.
 */
export const getOrderCount = async (payload) => {
    const { ids, ...filteredPayload } = payload;
    if (filteredPayload.status && filteredPayload.status.length === 0) {
        delete filteredPayload.status;
    }
    delete filteredPayload['page'];
    delete filteredPayload['pageSize'];
    delete filteredPayload['limit'];
    delete filteredPayload['sort'];
    delete filteredPayload['fields'];
    delete filteredPayload['totalLength'];

    const params = {
        ...filteredPayload,
        ...(filteredPayload.status && { status: filteredPayload.status.join(',') })
    }

    return await axios.get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders/count`, {
        headers: {
            ...HeaderBasic,
        },
        params: params
    })
        .then(res => res.data)
        .catch(Error => console.log(Error))
}


/**
 * @param {Object} payload - The payload for the request.
 * @param {Array<string>} [payload.status]
 * @param {string} payload key & valye
 * @returns {Promise<Object>} The response data containing the order count.
 */
export const getOrderList = async (payload) => {
    if (payload.status && payload.status.length === 0) {
        delete payload.status;
    }
    const params = {
        ...payload,
        ...(payload.status && { status: payload.status.join(',') })
    };
    return await axios.get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders`, {
        headers: {
            ...HeaderBasic,
        },
        params
    })
        .then(res => res.data)
        .catch(Error => console.log(Error))
};


export const getOrderFulfillmentPending = async (payload) => {

    const baiscParams = {
        status: 'paid,partially-refunded',
        fulfillmentStatus: 'pending',
        reeceived: 'false',
        refundStatus: '-requested',
        sort: '-createdAt',
    }

    return await axios.get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders`, {
        headers: {
            ...HeaderBasic,
        },
        params: {
            ...payload,
            ...baiscParams,
        }
    })
        .then(res => res.data)
        .catch(Error => console.log(Error))
}



/**
 * @param {Object} payload.tracking - The payload for the request.
 * @param {string} payload.orderId key & valye
 * @param {Array<string>} payload.items
 * @returns {Promise<Object>} The response data containing the order count.
 */
export const postFulfillmentItem = async (payload) => {
    const params = {
        items: payload.items,
        tracking: payload.tracking
    }
    return await axios.post(
        `${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders/${payload.orderId}/fulfillments`,
        params, 
        {
            headers: {
                ...HeaderBasic
            }
        }
    )
}

// 아이템 한개의 배송이행이력을 삭제하는 API
export const deleteFulfillmentItem = async (payload) => {
    return await axios.delete(
        `${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders/${payload.id1}/fulfillments/${payload.id2}`,
        {
            headers: {
                ...HeaderBasic
            }
        }
    )
}

export const getShippingPolicies = async () => {
    return await axios.get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/shipping/policies`, {
        headers: {
            ...HeaderBasic
        }
    })
    .then(res => res.data)
    .catch(Error => console.log(Error))
}
