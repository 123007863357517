export const reducerUtils = {
  initial : ( data = null )=>({
      loading : false,
      data,
      error : null
  }),
  loading : ( preveState = null ) => ({
      data : preveState,
      loading : true,
      error : null
  }),
  success : data => ({
      data ,
      loading : false,
      error : null 
  }),
  error : error => ({
      data : null ,
      loading : false,
      error 
  })
}

export const fetchAndWait = ( store ,  actionCreator )  => 
  new Promise(resolve => 
    { 
      store.dispatch(actionCreator); 
      const unsubscribe = store.subscribe(() => { 
          const state = store.getState(); 
          unsubscribe(); 
          return resolve(state); 
        }
      ); 
    }
  );
