import { takeLatest, put, all, fork, select, delay, call } from "redux-saga/effects";
import { playDashboardAction } from "./playDashboardSlice";
import * as playDashboardApi from "./playDashboardApi";
import createAsyncSaga from "../saga.util";

const getPlayChallengeDashboardSaga = createAsyncSaga(
  playDashboardApi.getPlayChallengeDashboard,
  playDashboardAction.getPlayChallengeDashboardSuccess,
  playDashboardAction.getPlayChallengeDashboardError
);
const getPlayPetDashboardSaga = createAsyncSaga(
  playDashboardApi.getPlayPetDashboard,
  playDashboardAction.getPlayPetDashboardSuccess,
  playDashboardAction.getPlayPetDashboardError
);
const getPlayTrendDashboardSaga = createAsyncSaga(
  playDashboardApi.getPlayTrendDashboard,
  playDashboardAction.getPlayTrendDashboardSuccess,
  playDashboardAction.getPlayTrendDashboardError
);
const getPlayUserDashboardSaga = createAsyncSaga(
  playDashboardApi.getPlayUserDashboard,
  playDashboardAction.getPlayUserDashboardSuccess,
  playDashboardAction.getPlayUserDashboardError
);
const getPlayUserDashboardCsvDownloadSaga = createAsyncSaga(
  playDashboardApi.getPlayUserDashboardCsvDownload,
  playDashboardAction.getPlayUserDashboardCsvDownloadSuccess,
  playDashboardAction.getPlayUserDashboardCsvDownloadError
);
const getPlayUserDashboardCsvFullDownloadSaga = createAsyncSaga(
  playDashboardApi.getPlayUserDashboardCsvFullDownload,
  playDashboardAction.getPlayUserDashboardCsvFullDownloadSuccess,
  playDashboardAction.getPlayUserDashboardCsvFullDownloadError
);
const getPlayUserDashboardCountSaga = createAsyncSaga(
  playDashboardApi.getPlayUserDashboardCount,
  playDashboardAction.getPlayUserDashboardCountSuccess,
  playDashboardAction.getPlayUserDashboardCountError
);
const getPlayChallengeDashboardDetailSaga = createAsyncSaga(
  playDashboardApi.getPlayChallengeDashboardDetail,
  playDashboardAction.getPlayChallengeDashboardDetailSuccess,
  playDashboardAction.getPlayChallengeDashboardDetailError
);
const getPlayUnitDashboardSaga = createAsyncSaga(
  playDashboardApi.getPlayUnitDashboard,
  playDashboardAction.getPlayUnitDashboardSuccess,
  playDashboardAction.getPlayUnitDashboardError
);
const getPlayUnitDashboardListSaga = createAsyncSaga(
  playDashboardApi.getPlayUnitDashboardList,
  playDashboardAction.getPlayUnitDashboardListSuccess,
  playDashboardAction.getPlayUnitDashboardListError
);
const getPlayUnitDashboardListAllSaga = createAsyncSaga(
  playDashboardApi.getPlayUnitDashboardListAll,
  playDashboardAction.getPlayUnitDashboardListAllSuccess,
  playDashboardAction.getPlayUnitDashboardListAllError
);
const getPlayUnitDashboardListCountSaga = createAsyncSaga(
  playDashboardApi.getPlayUnitDashboardListCount,
  playDashboardAction.getPlayUnitDashboardListCountSuccess,
  playDashboardAction.getPlayUnitDashboardListCountError
);
const getPlayTrendDashboardDetailSaga = createAsyncSaga(
  playDashboardApi.getPlayTrendDashboardDetail,
  playDashboardAction.getPlayTrendDashboardDetailSuccess,
  playDashboardAction.getPlayTrendDashboardDetailError
);

function* watchGetPlayChallengeDashboard() {
  yield takeLatest(playDashboardAction.getPlayChallengeDashboard, getPlayChallengeDashboardSaga);
}
function* watchGetPlayPetDashboard() {
  yield takeLatest(playDashboardAction.getPlayPetDashboard, getPlayPetDashboardSaga);
}
function* watchGetPlayTrendDashboard() {
  yield takeLatest(playDashboardAction.getPlayTrendDashboard, getPlayTrendDashboardSaga);
}
function* watchGetPlayUserDashboard() {
  yield takeLatest(playDashboardAction.getPlayUserDashboard, getPlayUserDashboardSaga);
}
function* watchGetPlayUserDashboardCsvDownload() {
  yield takeLatest(playDashboardAction.getPlayUserDashboardCsvDownload, getPlayUserDashboardCsvDownloadSaga);
}
function* watchGetPlayUserDashboardCsvFullDownload() {
  yield takeLatest(playDashboardAction.getPlayUserDashboardCsvFullDownload, getPlayUserDashboardCsvFullDownloadSaga);
}
function* watchGetPlayUserDashboardCount() {
  yield takeLatest(playDashboardAction.getPlayUserDashboardCount, getPlayUserDashboardCountSaga);
}
function* watchGetPlayChallengeDashboardDetail() {
  yield takeLatest(
    playDashboardAction.getPlayChallengeDashboardDetail,
    getPlayChallengeDashboardDetailSaga
  );
}
function* watchGetPlayUnitDashboard() {
  yield takeLatest(playDashboardAction.getPlayUnitDashboard, getPlayUnitDashboardSaga);
}
function* watchGetPlayUnitDashboardList() {
  yield takeLatest(playDashboardAction.getPlayUnitDashboardList, getPlayUnitDashboardListSaga);
}
function* watchGetPlayUnitDashboardListAll() {
  yield takeLatest(
    playDashboardAction.getPlayUnitDashboardListAll,
    getPlayUnitDashboardListAllSaga
  );
}
function* watchGetPlayUnitDashboardListCount() {
  yield takeLatest(
    playDashboardAction.getPlayUnitDashboardListCount,
    getPlayUnitDashboardListCountSaga
  );
}
function* watchGetPlayTrendDashboardDetail() {
  yield takeLatest(
    playDashboardAction.getPlayTrendDashboardDetail,
    getPlayTrendDashboardDetailSaga
  );
}

export function* playDashboardSaga() {
  yield all([
    fork(watchGetPlayChallengeDashboard),
    fork(watchGetPlayPetDashboard),
    fork(watchGetPlayTrendDashboard),
    fork(watchGetPlayUserDashboard),
    fork(watchGetPlayUserDashboardCsvDownload),
    fork(watchGetPlayUserDashboardCsvFullDownload),
    fork(watchGetPlayUserDashboardCount),
    fork(watchGetPlayChallengeDashboardDetail),
    fork(watchGetPlayUnitDashboard),
    fork(watchGetPlayUnitDashboardList),
    fork(watchGetPlayUnitDashboardListAll),
    fork(watchGetPlayUnitDashboardListCount),
    fork(watchGetPlayTrendDashboardDetail),
  ]);
}
