import { call, put } from "redux-saga/effects";
import { reducerUtils } from '@store/reducer.util';

function isPayloadAction( action ){
  return action.payload !== undefined;
}

export default function createAsyncSaga(
  promiseCreator ,
  successCreator,
  errorCreator,
) {
  return function* ( action){
    try {

      const response  = isPayloadAction(action) // payload 존재여부 확인
        ? yield call(promiseCreator, action.payload)
        : yield call(promiseCreator);

      yield put(successCreator(response));
    } catch(error) {
      yield put(errorCreator(error));
    }
  }
}   


export function createSagaReducer( key , targetState ){

  const [ REQUEST , SUCCESS , ERROR ] = [
    key,
    `${key}Success`,
    `${key}Error`
  ]

  return {
    [ REQUEST ] : function( state ) {
      state[targetState] = reducerUtils.loading()
    },
    [ SUCCESS ] : function( state , { payload }) {
      state[targetState] = reducerUtils.success(payload)
		},
		[ ERROR ] : function( state , { payload }) {
      state[targetState] = reducerUtils.error(payload)
    },
  }
}