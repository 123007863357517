import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'csClinic';

const initialState  = {
  response: reducerUtils.initial(),
  getCsClinicList: reducerUtils.initial(),
  getCsClinic: reducerUtils.initial(),
  updateCsClinic: reducerUtils.initial(),
  cancelRespectivelyCsClinic: reducerUtils.initial(),
  sendSchedule: reducerUtils.initial(),
  sendCsForm: reducerUtils.initial(),
  cacelAllClinic: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getCsClinicList' , 'getCsClinicList' ) ),
    ...( createSagaReducer('getCsClinic' , 'getCsClinic' ) ),
    ...( createSagaReducer('updateCsClinic' , 'updateCsClinic' ) ),
    ...( createSagaReducer('cancelRespectivelyCsClinic' , 'cancelRespectivelyCsClinic' ) ),
    ...( createSagaReducer('sendSchedule' , 'sendSchedule' ) ),
    ...( createSagaReducer('sendCsForm' , 'sendCsForm' ) ),
    ...( createSagaReducer('cacelAllClinic' , 'cacelAllClinic' ) ),

    reset(state) {
      state.getCsClinicList = reducerUtils.initial(),
      state.getCsClinic = reducerUtils.initial(),
      state.updateCsClinic = reducerUtils.initial(),
      state.cancelRespectivelyCsClinic = reducerUtils.initial(),
      state.sendSchedule = reducerUtils.initial(),
      state.sendCsForm = reducerUtils.initial(),
      state.cacelAllClinic = reducerUtils.initial()
    }
	},
});

export const CSCLINIC = slice.name;
export const csClinicReducer = slice.reducer;
export const csClinicAction  = slice.actions;