import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { keywordAction } from '@store/keyword/keywordSlice';
import * as keywordApi from '@store/keyword/keywordApi';
import createAsyncSaga from "@store/saga.util";

const getKeywordSaga = createAsyncSaga(
  keywordApi.getKeyword,
  keywordAction.getKeywordSuccess,
  keywordAction.getKeywordError,
)

const getKeywordsSaga = createAsyncSaga(
  keywordApi.getKeywords,
  keywordAction.getKeywordsSuccess,
  keywordAction.getKeywordsError,
)

const postKeywordsSaga = createAsyncSaga(
  keywordApi.insertKeyword,
  keywordAction.insertKeywordSuccess,
  keywordAction.insertKeywordError,
)

const putKeywordSaga = createAsyncSaga(
  keywordApi.updateKeyword,
  keywordAction.updateKeywordSuccess,
  keywordAction.updateKeywordError,
)

const getKeywordChallengeSaga = createAsyncSaga(
  keywordApi.getKeywordChallenge,
  keywordAction.getKeywordChallengeSuccess,
  keywordAction.getKeywordChallengeError,
)

const getKeywordPackageChallengeSaga = createAsyncSaga(
  keywordApi.getKeywordPackageChallenge,
  keywordAction.getKeywordPackageChallengeSuccess,
  keywordAction.getKeywordPackageChallengeError,
)

const deleteKeywordChallengeSaga = createAsyncSaga(
  keywordApi.deleteKeywordChallenge,
  keywordAction.deleteKeywordChallengeSuccess,
  keywordAction.deleteKeywordChallengeError,
)

const deleteKeywordPackageChallengeSaga = createAsyncSaga(
  keywordApi.deleteKeywordPackageChallenge,
  keywordAction.deleteKeywordPackageChallengeSuccess,
  keywordAction.deleteKeywordPackageChallengeError,
)

function* watchGetKeyword() {
  yield takeLatest( keywordAction.getKeyword , getKeywordSaga );
}

function* watchGetKeywords() {
  yield takeLatest( keywordAction.getKeywords , getKeywordsSaga );
}

function* watchPostKeyword() {
  yield takeLatest( keywordAction.insertKeyword , postKeywordsSaga );
}

function* watchPutKeyword() {
  yield takeLatest( keywordAction.updateKeyword , putKeywordSaga );
}

function* watchGetKeywordChallenge() {
  yield takeLatest( keywordAction.getKeywordChallenge , getKeywordChallengeSaga );
}

function* watchGetKeywordPackageChallenge() {
  yield takeLatest( keywordAction.getKeywordPackageChallenge , getKeywordPackageChallengeSaga );
}

function* watchDeleteKeywordChallenge() {
  yield takeLatest( keywordAction.deleteKeywordChallenge , deleteKeywordChallengeSaga );
}

function* watchDeleteKeywordPackageChallenge() {
  yield takeLatest( keywordAction.deleteKeywordPackageChallenge , deleteKeywordPackageChallengeSaga );
}

export function* keywordSaga() {
  yield all([
    fork(watchGetKeyword),
    fork(watchGetKeywords),
    fork(watchPostKeyword),
    fork(watchPutKeyword),
    fork(watchGetKeywordChallenge),
    fork(watchGetKeywordPackageChallenge),
    fork(watchDeleteKeywordChallenge),
    fork(watchDeleteKeywordPackageChallenge)
  ])
}