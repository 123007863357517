import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { petKindAction } from '@store/petKind/petKindSlice';
import * as petKindApi from '@store/petKind/petKindApi';
import createAsyncSaga from "@store/saga.util";

const getPetKindSaga = createAsyncSaga(
  petKindApi.getPetKind,
  petKindAction.getPetKindSuccess,
  petKindAction.getPetKindError,
)

const getPetKindsSaga = createAsyncSaga(
  petKindApi.getPetKinds,
  petKindAction.getPetKindsSuccess,
  petKindAction.getPetKindsError,
)

const postPetKindsSaga = createAsyncSaga(
  petKindApi.insertPetKind,
  petKindAction.insertPetKindSuccess,
  petKindAction.insertPetKindError,
)

const putPetKindSaga = createAsyncSaga(
  petKindApi.updatePetKind,
  petKindAction.updatePetKindSuccess,
  petKindAction.updatePetKindError,
)

function* watchGetPetKind() {
  yield takeLatest( petKindAction.getPetKind , getPetKindSaga );
}

function* watchGetPetKinds() {
  yield takeLatest( petKindAction.getPetKinds , getPetKindsSaga );
}

function* watchPostPetKind() {
  yield takeLatest( petKindAction.insertPetKind , postPetKindsSaga );
}

function* watchPutPetKind() {
  yield takeLatest( petKindAction.updatePetKind , putPetKindSaga );
}

export function* petKindSaga() {
  yield all([
    fork(watchGetPetKind),
    fork(watchGetPetKinds),
    fork(watchPostPetKind),
    fork(watchPutPetKind)
  ])
}