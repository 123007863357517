import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'csClinicResult';

const initialState  = {
  response: reducerUtils.initial(),
  getCsClinicResult: reducerUtils.initial(),
  getCalendarSchedules: reducerUtils.initial(),
  getCsClinicResultByCase: reducerUtils.initial(),
  updateCsClinicResult: reducerUtils.initial(),
  postResendReport: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getCsClinicResult' , 'getCsClinicResult' ) ),
    ...( createSagaReducer('getCalendarSchedules' , 'getCalendarSchedules' ) ),
    ...( createSagaReducer('getCsClinicResultByCase', 'getCsClinicResultByCase') ),
    ...( createSagaReducer('updateCsClinicResult', 'updateCsClinicResult') ),
    ...( createSagaReducer('postResendReport', 'postResendReport') ),
    reset(state) {
      state.getCsClinicResult = reducerUtils.initial(),
      state.getCalendarSchedules = reducerUtils.initial(),
      state.getCsClinicResultByCase = reducerUtils.initial(),
      state.updateCsClinicResult = reducerUtils.initial(),
      state.postResendReport = reducerUtils.initial()
    }
	},
});

export const CSCLINICRESULT = slice.name;
export const csClinicResultReducer = slice.reducer;
export const csClinicResultAction  = slice.actions;