import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'csFormResp';

const initialState  = {
  response: reducerUtils.initial(),
  getCsFormResp: reducerUtils.initial(),
  getCsFormRespDetails: reducerUtils.initial(),
  responseConfirm: reducerUtils.initial(),
  getCsCustInfo: reducerUtils.initial(),
  getCsPetInfo: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getCsFormResp' , 'getCsFormResp' ) ),
    ...( createSagaReducer('getCsFormRespDetails', 'getCsFormRespDetails')),
    ...( createSagaReducer('responseConfirm', 'responseConfirm' ) ),
    ...( createSagaReducer('getCsCustInfo', 'getCsCustInfo' ) ),
    ...( createSagaReducer('getCsPetInfo', 'getCsPetInfo' ) ),
    reset(state) {
      state.getCsFormResp = reducerUtils.initial()
      state.getCsFormRespDetails = reducerUtils.initial()
      state.responseConfirm = reducerUtils.initial()
      state.getCsCustInfo = reducerUtils.initial()
      state.getCsPetInfo = reducerUtils.initial()
    }
	},
});

export const CSFORMRESP = slice.name;
export const csFormRespReducer = slice.reducer;
export const csFormRespAction  = slice.actions;