import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getPetKind = async ({userToken, petKindId}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/pet/kind/select/${petKindId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getPetKinds = async ({userToken, data = null}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/pet/kind/select`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
}

export const insertPetKind = async ({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/pet/kind/insert`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
    }}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const updatePetKind = async ({userToken, data}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/pet/kind/update`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
    }}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
  
}