import { takeLatest, put, all, fork , select, delay, call} from "redux-saga/effects"
import { packageInChallengeAction } from '@store/packageInChallenge/packageInChallengeSlice';
import * as packageInChallengeApi from '@store/packageInChallenge/packageInChallengeApi';
import createAsyncSaga from "@store/saga.util";


const getPackageInChallengesSaga = createAsyncSaga(
  packageInChallengeApi.getPackageInChallenges,
  packageInChallengeAction.getPackageInChallengesSuccess,
  packageInChallengeAction.getPackageInChallengesError,
)

const putPackageInChallengeSaga = createAsyncSaga(
  packageInChallengeApi.updatePackageInChallenge,
  packageInChallengeAction.updatePackageInChallengeSuccess,
  packageInChallengeAction.updatePackageInChallengeError,
)

const deletePackageInChallengeSaga = createAsyncSaga(
  packageInChallengeApi.deletePackageInChallenge,
  packageInChallengeAction.deletePackageInChallengeSuccess,
  packageInChallengeAction.deletePackageInChallengeError,
)

function* watchGetPackageInChallenges() {
  yield takeLatest( packageInChallengeAction.getPackageInChallenges , getPackageInChallengesSaga );
}

function* watchPutPackageInChallenge() {
  yield takeLatest( packageInChallengeAction.updatePackageInChallenge , putPackageInChallengeSaga );
}

function* watchDeletePackageInChallenge() {
  yield takeLatest( packageInChallengeAction.deletePackageInChallenge , deletePackageInChallengeSaga );
}

export function* packageInChallengeSaga() {
  yield all([
    fork(watchGetPackageInChallenges),
    fork(watchPutPackageInChallenge),
    fork(watchDeletePackageInChallenge)
  ])
}