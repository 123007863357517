import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'pointBackend';

const initialState  = {
  response: reducerUtils.initial(),
  postUserPointPlusOrMinus: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('postUserPointPlusOrMinus' , 'postUserPointPlusOrMinus' ) ),
    reset: (state, action) => {
      state.postUserPointPlusOrMinus = reducerUtils.initial()
    },
	},
});

export const POINTBACKEND = slice.name;
export const pointBackendReducer = slice.reducer;
export const pointBackendAction = slice.actions;