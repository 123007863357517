export const HeaderBasic = {
  "Accept": "application/json",
  "Authorization":`Bearer ${process.env.NEXT_PUBLIC_CLAYFUL_SCRET_TOKEN}`
}

export const HeaderFormData = {
  "Content-Type" : "multipart/form-data",
  "Authorization":`Bearer ${process.env.NEXT_PUBLIC_CLAYFUL_SCRET_TOKEN}`
}

export const HeaderPoint = {
  "Accept": "application/json",
  "Authorization":`Bearer ${process.env.NEXT_PUBLIC_STORE_DEV_API_TOKEN}`
}