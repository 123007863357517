import axios from 'axios'
import { HeaderBasic } from '@store/api.util'


export const getAccounts = async () => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_ADMIN_API_HOST}/api/accounts`, 
    {
      headers: {
        ...HeaderBasic,
      },
    }).then(res => res.data)
}
