import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { eventAction } from '@store/event/eventSlice';
import * as eventApi from '@store/event/eventApi';
import createAsyncSaga from "@store/saga.util";

const getEventSaga = createAsyncSaga(
  eventApi.getEvent,
  eventAction.getEventSuccess,
  eventAction.getEventError,
)

const getEventsSaga = createAsyncSaga(
  eventApi.getEvents,
  eventAction.getEventsSuccess,
  eventAction.getEventsError,
)

const postEventSaga = createAsyncSaga(
  eventApi.insertEvent,
  eventAction.insertEventSuccess,
  eventAction.insertEventError,
)

const putEventSaga = createAsyncSaga(
  eventApi.updateEvent,
  eventAction.updateEventSuccess,
  eventAction.updateEventError,
)


function* watchGetEvent() {
  yield takeLatest( eventAction.getEvent , getEventSaga );
}

function* watchGetEvents() {
  yield takeLatest( eventAction.getEvents , getEventsSaga );
}

function* watchPostEvent() {
  yield takeLatest( eventAction.insertEvent , postEventSaga );
}

function* watchPutEvent() {
  yield takeLatest( eventAction.updateEvent , putEventSaga );
}

export function* eventSaga() {
  yield all([
    fork(watchGetEvent),
    fork(watchGetEvents),
    fork(watchPostEvent),
    fork(watchPutEvent),
  ])
}