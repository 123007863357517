import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { pointAction } from '@store/point/pointSlice';
import * as pointApi from '@store/point/pointApi';
import createAsyncSaga from "@store/saga.util";

const getUserPointAmountSaga = createAsyncSaga(
  pointApi.getUserPointAmount,
  pointAction.getUserPointAmountSuccess,
  pointAction.getUserPointAmountError,
)

const getUserPointEventSaga = createAsyncSaga(
  pointApi.getUserPointEvent,
  pointAction.getUserPointEventSuccess,
  pointAction.getUserPointEventError,
)

const getUserPointListSaga = createAsyncSaga(
  pointApi.getUserPointList,
  pointAction.getUserPointListSuccess,
  pointAction.getUserPointListError,
)

const getPointTotalSaga = createAsyncSaga(
  pointApi.getPointTotal,
  pointAction.getPointTotalSuccess,
  pointAction.getPointTotalError,
)

function* watchGetUserPointAmount() {
  yield takeLatest( pointAction.getUserPointAmount , getUserPointAmountSaga );
}

function* watchGetUserPointEvent() {
  yield takeLatest( pointAction.getUserPointEvent , getUserPointEventSaga );
}

function* watchGetUserPointList() {
  yield takeLatest( pointAction.getUserPointList , getUserPointListSaga );
}

function* watchGetPointTotal() {
  yield takeLatest( pointAction.getPointTotal , getPointTotalSaga );
}

export function* pointSaga() {
  yield all([
    fork(watchGetUserPointAmount),
    fork(watchGetUserPointEvent),
    fork(watchGetUserPointList),
    fork(watchGetPointTotal)
  ])
}