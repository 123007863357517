import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { userPlayAction } from './userPlaySlice'
import * as userPlayApi from './userPlayApi'
import createAsyncSaga from "../saga.util";

const getUserPlayEnteredHistorySaga = createAsyncSaga(
  userPlayApi.getUserPlayEnteredHistory,
  userPlayAction.getUserPlayEnteredHistorySuccess,
  userPlayAction.getUserPlayEnteredHistoryError,
)

function* watchGetUserPlayEnteredHistory() {
  yield takeLatest( userPlayAction.getUserPlayEnteredHistory , getUserPlayEnteredHistorySaga );
}

export function* userPlaySaga() {
  yield all([
    fork(watchGetUserPlayEnteredHistory),
  ])
}