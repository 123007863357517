import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getPackageChallenge = async ({userToken, challengePackageUid}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/package/select/${challengePackageUid}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getPackageChallenges = async ({userToken, data = null}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/package/select`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    }).catch((Error)=>{
      console.log(Error);
    })
}

export const insertPackageChallenge = async ({userToken, insertDataSet}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/package/insert`,
    insertDataSet,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
    }}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const updatePackageChallenge = async ({userToken, updateDataSet}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/package/update`,
    updateDataSet,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
    }}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
  
}