import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '../reducer.util';
import { createSagaReducer } from '../saga.util';

const name = 'userPlay';

const initialState  = {
  getUserPlayEnteredHistory: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getUserPlayEnteredHistory' , 'getUserPlayEnteredHistory' ) ),
	},
});

export const USERPLAY = slice.name;
export const userPlayReducer = slice.reducer;
export const userPlayAction = slice.actions;