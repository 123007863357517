import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'category';

const initialState  = {
  response: reducerUtils.initial(),
  getCategorys: reducerUtils.initial(),
  getCategoryDetail: reducerUtils.initial(),
  insertCategory: reducerUtils.initial(),
  updateCategory: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getCategorys' , 'getCategorys' ) ),
    ...( createSagaReducer('getCategoryDetail' , 'getCategoryDetail' ) ),
    ...( createSagaReducer('insertCategory' , 'insertCategory' ) ),
    ...( createSagaReducer('updateCategory' , 'updateCategory' ) ),
	},
  reset: (state) => {
    state.getCategorys = reducerUtils.initial()
    state.getCategoryDetail = reducerUtils.initial()
    state.insertCategory = reducerUtils.initial()
    state.updateCategory = reducerUtils.initial()
  },
});

export const CATEGORY = slice.name;
export const categoryReducer = slice.reducer;
export const categoryAction = slice.actions;