import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

// 케어예약 클리닉 결과 조회
export const getCsClinicResult = async ({userToken, csClinicId}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/result/${csClinicId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res =>  res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

// 케어예약 클리닉 결과 조회(id, seq)
export const getCsClinicResultByCase = async ({userToken, clinicData}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/result/report`,
    {
      params: clinicData,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      return Error
    })
}

// 케어예약 클리닉 결과 입력
export const updateCsClinicResult = async ({userToken, saveData}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/result`,
    saveData,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      return Error
    })
}

// 케어예약 클리닉 회의실 조회
export const getCalendarSchedules = async ({userToken, calenderDataset}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/play/care/manager/calendar`,
    {
      params: calenderDataset,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res =>  res.data.calendar_list
    ).catch((Error)=>{
      console.log(Error);
    })
}

// 레포트 재발송
export const postResendReport = async ({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/clinic/result/report`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
    }}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}