import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '../reducer.util';
import { createSagaReducer } from '../saga.util';
import { ORDER_SEARCH_OPTION_CREATED, ORDER_SEARCH_OPTION_CREATED_END, ORDER_SEARCH_PRODUCT_NAME } from '@constants/order';

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const name = 'salescheck';

const initialState = {
    getOrders: reducerUtils.initial(),
    selectedDateIndex: 0,
    [ORDER_SEARCH_PRODUCT_NAME]: "",
    searchOptions: {
        [ORDER_SEARCH_OPTION_CREATED]: dayjs().startOf('day').utcOffset(9).format('YYYY-MM-DDTHH:mm:ssZ'),
        [ORDER_SEARCH_OPTION_CREATED_END]: dayjs().endOf('day').utcOffset(9).format('YYYY-MM-DDTHH:mm:ssZ'),
    },
    salesResult: {
        '총 주문건수': 0,
        '총 주문금액': 0,
        '놀로상품 주문금액': 0,
        '놀로외상품 주문금액': 0,
    },
    salesChartData: []
}

const slice = createSlice({
    name,
    initialState,
    reducers: {
        ...createSagaReducer('getOrders', 'getOrders'),
        setSearchOptionsInd: (state, action) => {
            state.searchOptions = {
                ...state.searchOptions,
                [action.payload.key]: action.payload.value
            }
        },
        setSearchOptions: (state, action) => {
            state.searchOptions = action.payload
        },
        setSelectedDateIndex: (state, action) => {
            state.selectedDateIndex = action.payload;
        },
        setSearchOptionProductName: (state, action) => {
            state[ORDER_SEARCH_PRODUCT_NAME] = action.payload;
        },
        setSalesResult: (state, action) => {
            state.salesResult = action.payload;
        },
        setSalesChartData: (state, action) => {
            state.salesChartData = action.payload; 
        }
    }
})

export const SALESCHECK = slice.name;
export const salescheckReducer = slice.reducer;
export const salescheckAction = slice.actions;
