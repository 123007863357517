import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { commonCodeAction } from '@store/commonCode/commonCodeSlice';
import * as commonCodeApi from '@store/commonCode/commonCodeApi';
import createAsyncSaga from "@store/saga.util";

const getCommonCodeListSaga = createAsyncSaga(
  commonCodeApi.getCommonCodeList,
  commonCodeAction.getCommonCodeListSuccess,
  commonCodeAction.getCommonCodeListError,
)
const getCommonMainCodeListSaga = createAsyncSaga(
  commonCodeApi.getCommonMainCodeList,
  commonCodeAction.getCommonMainCodeListSuccess,
  commonCodeAction.getCommonMainCodeListError,
)
const insertCommonCodeSaga = createAsyncSaga(
  commonCodeApi.insertCommonCode,
  commonCodeAction.insertCommonCodeSuccess,
  commonCodeAction.insertCommonCodeError,
)
const deleteCommonCodeSaga = createAsyncSaga(
  commonCodeApi.deleteCommonCode,
  commonCodeAction.deleteCommonCodeSuccess,
  commonCodeAction.deleteCommonCodeError,
)
const updateCommonCodeSaga = createAsyncSaga(
  commonCodeApi.updateCommonCode,
  commonCodeAction.updateCommonCodeSuccess,
  commonCodeAction.updateCommonCodeError,
)
const updateCommonCodeListSaga = createAsyncSaga(
  commonCodeApi.updateCommonCodeList,
  commonCodeAction.updateCommonCodeListSuccess,
  commonCodeAction.updateCommonCodeListError,
)

function* watchGetCommonCodeList() {
  yield takeLatest( commonCodeAction.getCommonCodeList , getCommonCodeListSaga );
}
function* watchGetCommonMainCodeList() {
  yield takeLatest( commonCodeAction.getCommonMainCodeList , getCommonMainCodeListSaga );
}
function* watchInsertCommonCode() {
  yield takeLatest( commonCodeAction.insertCommonCode , insertCommonCodeSaga );
}
function* watchDeleteCommonCode() {
  yield takeLatest( commonCodeAction.deleteCommonCode , deleteCommonCodeSaga );
}
function* watchUpdateCommonCode() {
  yield takeLatest( commonCodeAction.updateCommonCode , updateCommonCodeSaga );
}
function* watchUpdateCommonCodeList() {
  yield takeLatest( commonCodeAction.updateCommonCodeList , updateCommonCodeListSaga );
}

export function* commonCodeSaga() {
  yield all([
    fork(watchGetCommonCodeList),
    fork(watchGetCommonMainCodeList),
    fork(watchInsertCommonCode),
    fork(watchDeleteCommonCode),
    fork(watchUpdateCommonCode),
    fork(watchUpdateCommonCodeList),
  ])
}