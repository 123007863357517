import { takeLatest, all, fork } from "redux-saga/effects"
import { knolloStoreAction } from '@store/knolloStore/knolloStoreSlice';
import * as knolloStoreApi from '@store/knolloStore/knolloStoreApi';
import createAsyncSaga from "@store/saga.util";


const deletePaidPendingSaga = createAsyncSaga(
  knolloStoreApi.deletePaidPending,
  knolloStoreAction.deletePaidPendingSuccess,
  knolloStoreAction.deletePaidPendingError,
)

function* watchdeletePaidPendingSaga() {
  yield takeLatest( knolloStoreAction.deletePaidPending , deletePaidPendingSaga );
}

export function* knolloStoreSaga() {
  yield all([
    fork(watchdeletePaidPendingSaga),
  ])
}