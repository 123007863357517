import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { pointBackendAction } from '@store/pointBackend/pointBackendSlice';
import * as pointBackendApi from '@store/pointBackend/pointBackendApi';
import createAsyncSaga from "@store/saga.util";

const postUserPointPlusOrMinusSaga = createAsyncSaga(
  pointBackendApi.postUserPointPlusOrMinus,
  pointBackendAction.postUserPointPlusOrMinusSuccess,
  pointBackendAction.postUserPointPlusOrMinusError,
)

function* watchPostUserPointPlusOrMinus() {
  yield takeLatest( pointBackendAction.postUserPointPlusOrMinus , postUserPointPlusOrMinusSaga );
}


export function* pointBackendSaga() {
  yield all([
    fork(watchPostUserPointPlusOrMinus)
  ])
}