import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { packageChallengeAction } from '@store/packageChallenge/packageChallengeSlice';
import * as packageChallengeApi from '@store/packageChallenge/packageChallengeApi';
import createAsyncSaga from "@store/saga.util";

const getPackageChallengeSaga = createAsyncSaga(
  packageChallengeApi.getPackageChallenge,
  packageChallengeAction.getPackageChallengeSuccess,
  packageChallengeAction.getPackageChallengeError,
)

const getPackageChallengesSaga = createAsyncSaga(
  packageChallengeApi.getPackageChallenges,
  packageChallengeAction.getPackageChallengesSuccess,
  packageChallengeAction.getPackageChallengesError,
)

const postPackageChallengeSaga = createAsyncSaga(
  packageChallengeApi.insertPackageChallenge,
  packageChallengeAction.insertPackageChallengeSuccess,
  packageChallengeAction.insertPackageChallengeError,
)

const putPackageChallengeSaga = createAsyncSaga(
  packageChallengeApi.updatePackageChallenge,
  packageChallengeAction.updatePackageChallengeSuccess,
  packageChallengeAction.updatePackageChallengeError,
)

function* watchGetPackageChallenge() {
  yield takeLatest( packageChallengeAction.getPackageChallenge , getPackageChallengeSaga );
}

function* watchGetPackageChallenges() {
  yield takeLatest( packageChallengeAction.getPackageChallenges , getPackageChallengesSaga );
}

function* watchPostPackageChallenge() {
  yield takeLatest( packageChallengeAction.insertPackageChallenge , postPackageChallengeSaga );
}

function* watchPutPackageChallenge() {
  yield takeLatest( packageChallengeAction.updatePackageChallenge , putPackageChallengeSaga );
}


export function* packageChallengeSaga() {
  yield all([
    fork(watchGetPackageChallenge),
    fork(watchGetPackageChallenges),
    fork(watchPostPackageChallenge),
    fork(watchPutPackageChallenge)
  ])
}