import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'csForm';

const initialState  = {
  response: reducerUtils.initial(),
  getCsFormMst: reducerUtils.initial(),
  getCsFormMstList: reducerUtils.initial(),
  getCsFormDetail: reducerUtils.initial(),
  getCsFormDetailCts: reducerUtils.initial(),
  insertCsFormMst: reducerUtils.initial(),
  updateCsFormMst: reducerUtils.initial(),
  getSectionMst: reducerUtils.initial(),
  saveCsFormDtl: reducerUtils.initial(),
  saveCsFormDtlCts: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getCsFormMst' , 'getCsFormMst' ) ),
    ...( createSagaReducer('getCsFormMstList' , 'getCsFormMstList' ) ),
    ...( createSagaReducer('getCsFormDetail', 'getCsFormDetail' ) ),
    ...( createSagaReducer('getCsFormDetailCts', 'getCsFormDetailCts' ) ),
    ...( createSagaReducer('insertCsFormMst' , 'insertCsFormMst' ) ),    
    ...( createSagaReducer('updateCsFormMst' , 'updateCsFormMst' ) ), 
    ...( createSagaReducer('getSectionMst' , 'getSectionMst' ) ), 
    ...( createSagaReducer('saveCsFormDtl' , 'saveCsFormDtl' ) ), 
    ...( createSagaReducer('saveCsFormDtlCts' , 'saveCsFormDtlCts' ) ),
    reset(state) {
      //state.getCsFormMst = reducerUtils.initial(),
      state.getCsFormDetail = reducerUtils.initial(),
      state.getCsFormDetailCts = reducerUtils.initial(),
      state.insertCsFormMst = reducerUtils.initial(),
      state.updateCsFormMst = reducerUtils.initial(),
      state.getSectionMst = reducerUtils.initial(),
      state.saveCsFormDtl = reducerUtils.initial(),
      state.saveCsFormDtlCts = reducerUtils.initial()
    }
	},
});

export const CSFORM = slice.name;
export const csFormReducer = slice.reducer;
export const csFormAction = slice.actions;