import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getCsFormResp = async ({userToken, data = null}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/answers`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res =>  res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getCsFormRespDetails = async ({userToken}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/answers/details`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res =>  res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const responseConfirm = async ({userToken, dataset}) => {

  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/answers/confirm`,
    dataset,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    });
}

export const getCsCustInfo = async ({userToken, qstnMstId}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/answers/cust/${qstnMstId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res =>  res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getCsPetInfo = async ({userToken, qstnMstId}) => {

  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/answers/pet/${qstnMstId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res =>  res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}
