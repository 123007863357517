import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getCommonCodeList = async ({userToken, params = null }) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/commonCode`,
    {
      params: params,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getCommonMainCodeList = async ({userToken, params = null }) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/commonCode/main`,
    {
      params: params,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const insertCommonCode = async ({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/commonCode/insert`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const deleteCommonCode = async ({userToken, data}) => {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/commonCode/delete`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const updateCommonCode = async ({userToken, data}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/commonCode/update`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })  
}

export const updateCommonCodeList = async ({userToken, commonCodeList}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/commonCode/update/list`,
    commonCodeList,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })  
}