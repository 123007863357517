import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getChallenge = async ({userToken, challengeUid}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/select/${challengeUid}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getChallenges = async ({userToken, data = null}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/select`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
}

export const insertChallenge = async ({userToken, insertDataSet}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/insert`,
    insertDataSet,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const updateChallenge = async ({userToken, updateDataSet}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/update`,
    updateDataSet,
    { headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
  
}

export const getOneMinuteChallengeList = async ({userToken, data = null}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/oneMinute`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
}