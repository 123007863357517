import { takeLatest, put, all, fork , select, delay} from "redux-saga/effects"
import { csFormAction } from '@store/csb/form/csFormSlice';
import * as csFormApi from '@store/csb/form/csFormApi';
import createAsyncSaga from "@store/saga.util";
import { create } from "lodash";

const getCsFormMstSaga = createAsyncSaga(
  csFormApi.getCsFormMst,
  csFormAction.getCsFormMstSuccess,
  csFormAction.getCsFormMstError,
)

const getCsFormMstListSaga = createAsyncSaga(
  csFormApi.getCsFormMstList,
  csFormAction.getCsFormMstListSuccess,
  csFormAction.getCsFormMstListError,
)

const getCsFormDetailSaga = createAsyncSaga(
  csFormApi.getCsFormDetail,
  csFormAction.getCsFormDetailSuccess,
  csFormAction.getCsFormDetailError,
)

const getCsFormDetailCtsSaga = createAsyncSaga(
  csFormApi.getCsFormDetailCts,
  csFormAction.getCsFormDetailCtsSuccess,
  csFormAction.getCsFormDetailCtsError,
)

const postCsFormMstSaga = createAsyncSaga(
  csFormApi.insertCsFormMst,
  csFormAction.insertCsFormMstSuccess,
  csFormAction.insertCsFormMstError,
)

const putCsFormMstSaga = createAsyncSaga(
  csFormApi.updateCsFormMst,
  csFormAction.updateCsFormMstSuccess,
  csFormAction.updateCsFormMstError,
)

// Section 정보 조회(cs_section_mst)
const getSectionMstSaga = createAsyncSaga(
  csFormApi.getSectionMst,
  csFormAction.getSectionMstSuccess,
  csFormAction.getSectionMstError,
)
// 문항 상세 저장(cs_form_dtl)
const getSaveCsFormDtlSaga = createAsyncSaga(
  csFormApi.saveCsFormDtl,
  csFormAction.saveCsFormDtlSuccess,
  csFormAction.saveCsFormDtlError,
)
// 문항 상세 내용 저장(cs_form_dtl_cts)
const getSaveCsFormDtlCtsSaga = createAsyncSaga(
  csFormApi.saveCsFormDtlCts,
  csFormAction.saveCsFormDtlCtsSuccess,
  csFormAction.saveCsFormDtlCtsError,
)
function* watchGetCsFormMst() {
  yield takeLatest( csFormAction.getCsFormMst , getCsFormMstSaga );
}

function* watchGetCsFormMstList() {
  yield takeLatest( csFormAction.getCsFormMstList , getCsFormMstListSaga );
}

function* watchPostCsFormMst() {
  yield takeLatest( csFormAction.insertCsFormMst , postCsFormMstSaga );
}

function* watchPutCsFormMst() {
  yield takeLatest( csFormAction.updateCsFormMst , putCsFormMstSaga );
}

function* watchGetCsFormDetail() {
  yield takeLatest( csFormAction.getCsFormDetail , getCsFormDetailSaga );
}

function* watchGetCsFormDetailCts() {
  yield takeLatest( csFormAction.getCsFormDetailCts, getCsFormDetailCtsSaga);
}

function* watchGetSectionMst() {
  yield takeLatest( csFormAction.getSectionMst , getSectionMstSaga );
}

function* watchSaveCsFormDtl() {
  yield takeLatest( csFormAction.saveCsFormDtl , getSaveCsFormDtlSaga );
}

function* watchSaveCsFormDtlCts() {
  yield takeLatest( csFormAction.saveCsFormDtlCts , getSaveCsFormDtlCtsSaga );
}
export function* csFormSaga() {
  yield all([
    fork(watchGetCsFormMst),
    fork(watchGetCsFormMstList),
    fork(watchPostCsFormMst),
    fork(watchPutCsFormMst),
    fork(watchGetCsFormDetail),
    fork(watchGetCsFormDetailCts),
    fork(watchGetSectionMst),
    fork(watchSaveCsFormDtl),
    fork(watchSaveCsFormDtlCts),
  ])
}