import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getCsFormMst = async ({userToken, id}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms/${id}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getCsFormMstList = async ({userToken, data = null}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
}

export const getCsFormDetail = async ({userToken, id}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms/details/${id}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
}

export const getCsFormDetailCts = async ({userToken, id}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms/details/cts/${id}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
}

export const insertCsFormMst = async ({userToken, insertDataSet}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms`,
    insertDataSet,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const updateCsFormMst = async ({userToken, updateDataSet}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms`,
    updateDataSet,
    { headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

// Section 정보 조회(cs_section_mst)
export const getSectionMst = async ({userToken, data = null}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/sections`,
    { 
      params: data,
      headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

// 문항 상세 저장(cs_form_dtl)
export const saveCsFormDtl = async({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms/details`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

// 문항 상세 내용 저장(cs_form_dtl_cts)
export const saveCsFormDtlCts = async({userToken, data}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/care/forms/details/cts`,
    data,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}
