import axios from "axios";
import { HeaderBasic } from "../api.util";

export const getPlayChallengeDashboard = async ({ userToken, days }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/challenge/${days}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayPetDashboard = async ({ userToken, days }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/pet/${days}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayTrendDashboard = async ({ userToken, days }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/trend/${days}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUserDashboard = async ({ userToken, data }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/user`, {
      params: data,
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUserDashboardCsvDownload = async ({ userToken }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/user/download`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUserDashboardCsvFullDownload = async ({ userToken }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/user/fullDownload`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUserDashboardCount = async ({ userToken }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/user/count`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayChallengeDashboardDetail = async ({ userToken, date }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/challenge/detail/${date}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUnitDashboard = async ({ userToken, date }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/unit/dashboard`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUnitDashboardList = async ({ userToken, data }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/unit/dashboard/list`, {
      params: data,
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUnitDashboardListAll = async ({ userToken }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/unit/dashboard/list`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayUnitDashboardListCount = async ({ userToken, data }) => {
  console.log("getPlayUnitDashboardListCount");
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/unit/dashboard/list/count`, {
      params: data,
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPlayTrendDashboardDetail = async ({ userToken, challengeUid }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/play/trend/detail/${challengeUid}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};
