import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "../reducer.util";
import { createSagaReducer } from "../saga.util";

const name = "playDashboard";

const initialState = {
  getPlayChallengeDashboard: reducerUtils.initial(),
  getPlayPetDashboard: reducerUtils.initial(),
  getPlayTrendDashboard: reducerUtils.initial(),
  getPlayUserDashboard: reducerUtils.initial(),
  getPlayUserDashboardCsvDownload: reducerUtils.initial(),
  getPlayUserDashboardCsvFullDownload: reducerUtils.initial(),
  getPlayUserDashboardCount: reducerUtils.initial(),
  getPlayChallengeDashboardDetail: reducerUtils.initial(),
  getPlayUnitDashboard: reducerUtils.initial(),
  getPlayUnitDashboardList: reducerUtils.initial(),
  getPlayUnitDashboardListAll: reducerUtils.initial(),
  getPlayUnitDashboardListCount: reducerUtils.initial(),
  getPlayTrendDashboardDetail: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getPlayChallengeDashboard", "getPlayChallengeDashboard"),
    ...createSagaReducer("getPlayPetDashboard", "getPlayPetDashboard"),
    ...createSagaReducer("getPlayTrendDashboard", "getPlayTrendDashboard"),
    ...createSagaReducer("getPlayUserDashboard", "getPlayUserDashboard"),
    ...createSagaReducer("getPlayUserDashboardCsvDownload", "getPlayUserDashboardCsvDownload"),
    ...createSagaReducer("getPlayUserDashboardCsvFullDownload", "getPlayUserDashboardCsvFullDownload"),
    ...createSagaReducer("getPlayUserDashboardCount", "getPlayUserDashboardCount"),
    ...createSagaReducer("getPlayChallengeDashboardDetail", "getPlayChallengeDashboardDetail"),
    ...createSagaReducer("getPlayUnitDashboard", "getPlayUnitDashboard"),
    ...createSagaReducer("getPlayUnitDashboardList", "getPlayUnitDashboardList"),
    ...createSagaReducer("getPlayUnitDashboardListAll", "getPlayUnitDashboardListAll"),
    ...createSagaReducer("getPlayUnitDashboardListCount", "getPlayUnitDashboardListCount"),
    ...createSagaReducer("getPlayTrendDashboardDetail", "getPlayTrendDashboardDetail"),
    reset: (state) => {
      state.getPlayChallengeDashboard = reducerUtils.initial();
      state.getPlayPetDashboard = reducerUtils.initial();
      state.getPlayTrendDashboard = reducerUtils.initial();
      state.getPlayUserDashboard = reducerUtils.initial();
      state.getPlayUserDashboardCsvDownload = reducerUtils.initial();
      state.getPlayUserDashboardCsvFullDownload = reducerUtils.initial();
      state.getPlayUserDashboardCount = reducerUtils.initial();
      state.getPlayChallengeDashboardDetail = reducerUtils.initial();
      state.getPlayUnitDashboard = reducerUtils.initial();
      state.getPlayUnitDashboardList = reducerUtils.initial();
      state.getPlayUnitDashboardListAll = reducerUtils.initial();
      state.getPlayUnitDashboardListCount = reducerUtils.initial();
      state.getPlayTrendDashboardDetail = reducerUtils.initial();
    },
  },
});

export const PLAYDASHBOARD = slice.name;
export const playDashboardReducer = slice.reducer;
export const playDashboardAction = slice.actions;
