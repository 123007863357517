import axios from 'axios'
import { HeaderBasic } from '@store/api.util'


export const getPackageInChallenges = async ({userToken, data}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/package/in/select`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    }).catch((Error)=>{
      console.log(Error);
    })
}

export const updatePackageInChallenge = async ({userToken, challengeList}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/package/in/update`,
    challengeList,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
    },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
  
}

export const deletePackageInChallenge = async ({userToken, insertDataSet = null}) => {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/challenge/package/in/delete`,
    {
      params: insertDataSet,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}