import axios from 'axios'
import { HeaderBasic } from '@store/api.util'

export const getEvent = async ({userToken, displayId}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/event/select/${displayId}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getEvents = async ({userToken, data = null}) => {
  return axios.get(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/event/select`,
    {
      params: data,
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then((res)=>{
      return res.data;
    })
    
}

export const insertEvent = async ({userToken, insertDataSet}) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/event/insert`,
    insertDataSet,
    {headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}

export const updateEvent = async ({userToken, updateDataSet}) => {
  return axios.put(
    `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/event/update`,
    updateDataSet,
    { headers: {
      "Accept" : "application/json",
      "Authorization-Play" : "Bearer "+userToken
   },}
  ).then(
    res => res.data
  ).catch((Error) => {
    return Error
  })
}