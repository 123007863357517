import { createSlice } from '@reduxjs/toolkit';

const name = 'snackbar';

const initialState = {
    isOpen: false,
    type: '', // success, error, warning, info
    message: '',
}

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setSnackbar: (state, action) => {
            state.isOpen = true;
            state.type = action.payload.type;
            state.message = action.payload.message;
        },
        resetSnackbar: (state) => {
            state.isOpen = false;
            state.type = '';
            state.message = '';
        },
    }
})

export const SNACKBAR = slice.name;
export const snackbarReducer = slice.reducer;
export const snackbarAction = slice.actions;
