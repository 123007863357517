import axios from 'axios'

export const SERVER_API_HOST = process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST

export const getDashboardCount = async ({userToken, data = null}) => {
  
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/select`,
    {
      params: data,
      headers: {
          "Accept" : "application/json",
          "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardChartProgress = async ({userToken, days}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/selectChartProgress/${days}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardChartChallenge = async ({userToken, days}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/selectChartChallenge/${days}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardChartEnter = async ({userToken, days}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/selectChartEnter/${days}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
     },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardChartReward = async ({userToken, days}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/selectChartReward/${days}`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardChallenge = async ({userToken}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/challenge`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardPet = async ({userToken}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/pet`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardJudged = async ({userToken}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/judged`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardEnteredStatus = async ({userToken}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/enteredStatus`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardPackage = async ({userToken}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/package`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardTotalPet = async ({userToken}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/pet/total`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}

export const getDashboardChart = async ({userToken}) => {
  return axios.get(
    `${SERVER_API_HOST}/admin/dashboard/chart`,
    {
      headers: {
        "Accept" : "application/json",
        "Authorization-Play" : "Bearer "+userToken
      },
    }).then(
      res => res.data
    ).catch((Error)=>{
      console.log(Error);
    })
}