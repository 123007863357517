import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'challenge';

const initialState  = {
  response: reducerUtils.initial(),
  insertChallenge: reducerUtils.initial(),
  getChallenge: reducerUtils.initial(),
  getChallenges: reducerUtils.initial(),
  updateChallenge: reducerUtils.initial(),
  getOneMinuteChallengeList: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getChallenge' , 'getChallenge' ) ),
    ...( createSagaReducer('getChallenges' , 'getChallenges' ) ),
    ...( createSagaReducer('insertChallenge' , 'insertChallenge' ) ),
    ...( createSagaReducer('updateChallenge' , 'updateChallenge' ) ),
    ...( createSagaReducer('getOneMinuteChallengeList' , 'getOneMinuteChallengeList' ) ), 
    reset(state) {
      state.getChallenge = reducerUtils.initial(),
      state.getChallenges = reducerUtils.initial(),
      state.insertChallenge = reducerUtils.initial(),
      state.updateChallenge = reducerUtils.initial(),
      state.getOneMinuteChallengeList = reducerUtils.initial()
    }
	},
});

export const CHALLENGE = slice.name;
export const challengeReducer = slice.reducer;
export const challengeAction = slice.actions;