import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '@store/reducer.util';
import { createSagaReducer } from '@store/saga.util';

const name = 'petKind';

const initialState  = {
  insertPetKind: reducerUtils.initial(),
  getPetKind: reducerUtils.initial(),
  getPetKinds: reducerUtils.initial(),
  updatePetKind: reducerUtils.initial(),
};

const slice = createSlice({
  name ,
  initialState ,
  reducers : {
    ...( createSagaReducer('getPetKind' , 'getPetKind' ) ),
    ...( createSagaReducer('getPetKinds' , 'getPetKinds' ) ),
    ...( createSagaReducer('insertPetKind' , 'insertPetKind' ) ),
    ...( createSagaReducer('updatePetKind' , 'updatePetKind' ) ),
	},
});

export const PETKIND = slice.name;
export const petKindReducer = slice.reducer;
export const petKindAction = slice.actions;